import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Dialog} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {config} from '../../constants/config';
import PopupListItemCard from './popup_list_item';
import '../../../src/styles/popup.css';

const styles = (theme) => ({
  root: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    color: '#024182',
  },
});

const OuterDivContent = styled.div`
  width: 100%;
  padding: 0% 6% 4% 6%;
  flex-direction: column;
  display: flex;
`;

const SubTitleStyle = styled.h1`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  // color: #5b5b5b;
  color: #000000;
  line-height: 28px;
`;

const UnderLine = styled.hr`
  color: #eaeaea !important;
  margin: 0 !important;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px !important;
  border: none;
  border-top: 1px solid #eaeaea !important;
`;

const StyledImage = styled.img`
  width: 20%;
  align-self: center;
  @media ${(props) => props.theme.mediaQueries.medium} {
    width: auto;
  }
  @media ${(props) => props.theme.mediaQueries.small} {
    width: auto;
  }
`;

const closeStyle = {
  fontSize: '30px',
  fontStyle: 'bold',
  fontWeight: 'bold',
};

export default function PopupCards({onClosePopup, data, isModalOpen}) {
  const [open, setOpen] = React.useState(false);
  // console.log("asdasdasdas", data[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClosePopup();
    setOpen(false);
  };

  useEffect(() => {
    handleClickOpen();
  }, []);

  const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;

    return (
      <MuiDialogTitle
        disableTypography
        className={classes.root}
        {...other}
        style={{paddingRight: '6%'}}>
        {onClose ? (
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            style={{padding: '20px'}}>
            <CloseIcon
              style={closeStyle}
              className={classes.closeButton}
              onClick={onClose}
            />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
    },
  }))(MuiDialogContent);
  const imageUrl = `${config.BASE_BACKEND_URL}${data[0].image}${config.CMS_PARAMETERS}`;
  return (
    <div>
      {data ? (
        <Dialog
          open={isModalOpen}
          className={'home_page_footer_pop_up footerPopup'}
          onClose={handleClose}
          fullWidth
          maxWidth={'md'}>
          <DialogContent style={{borderRadius: '16px'}}>
            <DialogTitle onClose={handleClose}></DialogTitle>
            <OuterDivContent>
              <SubTitleStyle>{data[0].title}</SubTitleStyle>
              <UnderLine />
              <StyledImage src={imageUrl} alt="dialog" />
              <PopupListItemCard data={data} />
            </OuterDivContent>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}
