import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import rightarrow from '../../assets/images/popUpArrow.png';

const MainWrapper = styled.section`
  width: 60%;
  border-radius: 6px;
  margin-top: 10%;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 75%;
  }
`;
const StyledButton = styled(Link)`
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-decoration: none;
  height: 50px;
  color: #fff;
  letter-spacing: 0.329721px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  background: linear-gradient(147.87deg, #00529c 19.71%, #003768 98.37%);
  @media ${(props) => props.theme.mediaQueries.small} {
    height: 35px;
  }
`;

const Image = styled.img`
  width: 25px;
  margin-left: 10px;
`;

const PopupButton = (props) => {
  return (
    <MainWrapper>
      {props.buttonLink.includes('http') ? (
        <StyledButton to={{pathname: props.buttonLink}} target="_blank">
          {props.buttonTitle}
          <Image src={rightarrow} alt="right arrow" />
        </StyledButton>
      ) : (
        <StyledButton to={props.buttonLink} target="_blank">
          {props.buttonTitle}
          <Image src={rightarrow} alt="right arrow" />
        </StyledButton>
      )}
    </MainWrapper>
  );
};

export default PopupButton;
