import {JSEncrypt} from 'jsencrypt';
import CryptoJS from 'crypto-js';
import {getDownloadURL, ref} from 'firebase/storage';
import {storage} from './firebase';

export function formatNumInCommas(val) {
  const formattedCommaStr = val
    .toString()
    // .replace(/[^0-9]/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // .replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");

  return formattedCommaStr;
}

export function formatCurrency(n) {
  // format number 1000000 to 1,234,567
  if (n && n.length > 0) {
    return n
      .replace(/\D/g, '')
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
  } else {
    return n;
  }
}

export const numberAddComma = (num) => {
  num = Number(num);
  var n1, n2;
  num = num + '' || '';
  // works for integer and floating as well
  n1 = num.split('.');
  n2 = n1[1] || null;
  n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  num = n2 ? n1 + '.' + n2 : n1;
  return num;
};

export const addCommaDecimalNumber = (val, decimalPosition = 2) => {
  return val
    ? 'INR ' +
        parseFloat(val)
          .toFixed(decimalPosition)
          .replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, '$1,')
    : '';
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const scrollToTop = () => window.scrollTo(0, 0);

export const getExtension = (fileName) => {
  fileName.split('.').pop();
};

export function getCommaSeparatedTwoDecimalsNumber(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export function getCommaSeparatedDecimalsNumber(number) {
  const formatted = Number(number).toLocaleString('en', options);
  // console.log(formatted);
  return formatted;
}

export const encrypt = (text, secret) => {
  try {
    var sign = new JSEncrypt();
    // var public_key = '-----BEGIN PUBLIC KEY-----';
    var public_key =
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApGYhuHa5I24Kv9722n5ZYF9NWJORNnXx4YfWEDRRx7ywtggPK6/Eh+HhQiduuGwWflqb5NeI++taijIRj3IIVzuVVdpW9by3ptdjAhhZN3W53gLY/1ih1vprDxhB662N2S00yb3NVuZ5XI0i9DkT/YnOZiCIoEXbFnz1GRDA5j2TYKpPZHxUO9IUGoFz3ZXwmqijKK7tc2LEnzK7IE7P12MEeO3Bg6MsMfQBxxKonzkdr8/KQD2PlPy0cnQ6oFFitbTinXWJ0JIp8/WrOkbmC5ov5cMRb08Gbs5qmOgAdX0fJ3Zk5XUOXNfICaRsaRJGgMTZ9DodpzQ/MKvGLsYvaQIDAQAB';
    sign.setPublicKey(public_key);
    var s = sign.encrypt(secret);
    var salt = CryptoJS.lib.WordArray.random(128 / 8);
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    var cryptoKey = CryptoJS.PBKDF2(secret, salt, {
      keySize: 256 / 32,
      iterations: 100,
    }); /* key */

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), cryptoKey, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    var transitmessage = {
      part1: salt.toString() + iv.toString() + encrypted.toString(),
      part2: s,
    };
    return transitmessage;
  } catch (e) {}
};

export const downloadUrl = async (baseUrl, url) => {
  return getDownloadURL(ref(storage, `${baseUrl}${url}`))
    .then((url) => {
      window.open(url, '_blank');
      return true;
    })
    .catch((err) => {
      return false;
    });
};

// export const firebaseDownloadUtil

export const getFinancialYearDropdownList = (minYear = 1995) => {
  //2020-21 type
  const currentYear = new Date().getFullYear();
  const tempArray = [];
  for (let i = currentYear; i >= minYear; i--) {
    var secondSide = i;
    secondSide = secondSide % 100;
    secondSide = ('0' + secondSide).slice(-2);
    tempArray.push({title: `${i - 1} - ${secondSide}`});
  }
  return tempArray;
  //list of financial years from 1995- current years
};

export const currentFinancialYear = () => {
  //for dropdown
  var fiscalYear = '';
  var today = new Date();
  if (today.getMonth() + 1 <= 3) {
    fiscalYear = today.getFullYear() - 1 + '-' + today.getFullYear();
  } else {
    fiscalYear = today.getFullYear() + '-' + (today.getFullYear() + 1);
  }
  return {title: fiscalYear};
};

export const prevFinancialYear = () => {
  //for dropdown
  var prevYear = '';
  var today = new Date();
  if (today.getMonth() + 1 <= 3) {
    prevYear = today.getFullYear() - 2 + '-' + today.getFullYear - 1();
  } else {
    prevYear = today.getFullYear() - 1 + '-' + today.getFullYear();
  }
  return {title: prevYear};
};

export const getFinancialYearDropdownListLong = (minYear = 1995) => {
  //2020-2021 type
  const currentYear = new Date().getFullYear();
  const tempArray = [];
  for (let i = currentYear; i >= minYear; i--) {
    tempArray.push({title: `${i - 1} - ${i}`});
  }
  return tempArray;
  //list of financial years from 1995- current years
};
export const getLastFiveFinancialYear = () => {
  var today = new Date();
  const currentYear =
    today.getMonth() + 1 <= 3 ? today.getFullYear() - 1 : today.getFullYear();
  const tempArray = [];
  for (let i = currentYear; i >= currentYear - 4; i--) {
    tempArray.push({title: `${i} - ${i + 1}`});
  }
  return tempArray;
};
export const getFinancialYear = () => {
  let fiscalyear = '';
  const today = new Date();
  if (today.getMonth() + 1 <= 3) {
    //if more than 3rd month (April), finYear starts from current year
    fiscalyear = today.getFullYear() - 1 + '-' + today.getFullYear();
  } else {
    fiscalyear = today.getFullYear() + '-' + (today.getFullYear() + 1);
  }
  return fiscalyear.split('-');
};

export const financialYearBankFormat = (selectedYear) => {
  var temp = parseInt(selectedYear.substring(0, 4));
  temp = temp + 1;
  temp = temp.toString();
  var temp2 = parseInt(selectedYear.slice(-2));
  temp2 = temp2 + 1;
  temp2 = ('0' + temp2).slice(-2);
  temp2 = temp2.toString();
  return `${temp}-${temp2}`;
};

export const decrypt = (text, secret) => {
  var key = CryptoJS.PBKDF2(
    secret,
    CryptoJS.enc.Hex.parse(text.substr(0, 32)) /* Salt */,
    {keySize: 256 / 32, iterations: 100},
  );
  var decrypted = CryptoJS.AES.decrypt(
    text.substring(64) /* encrypted */,
    key,
    {
      iv: CryptoJS.enc.Hex.parse(text.substr(32, 32)) /* iv */,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    },
  );
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

export const idleTimeOutEvents = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];

export const downloadPdf = (links, ids, name) => {
  let linkSource = [];
  links.map((a) => {
    linkSource.push('data:application/octet-stream;base64,' + a);
  });
  const downloadLink = document.createElement('a');
  let newFileName = ids.map((el) => {
    const fileName = `${el}_${name}.pdf`;
    return fileName;
  });
  for (var i = 0; i < linkSource.length; i++) {
    downloadLink.href = linkSource[i];
    downloadLink.download = newFileName[i];
    downloadLink.click();
  }
};

export const getCurrentFinancialYear = () => {
  //get current date
  var today = new Date();

  //get current month
  var curMonth = today.getMonth();

  var financialYear = '';
  if (curMonth > 3) {
    //
    var nextYr1 = (today.getFullYear() + 1).toString();
    financialYear =
      '1st Apr,' + today.getFullYear().toString() + '-' + '31st Mar,' + nextYr1;
  } else {
    var nextYr2 = today.getFullYear().toString();
    financialYear =
      '1st Apr,' +
      (today.getFullYear() - 1).toString() +
      '-' +
      '31st Mar,' +
      nextYr2;
  }
  return financialYear;
};

export const removeTime = (dateToday) => {
  var date = new Date(dateToday).toLocaleDateString();
  return date;
};
export const getRepayScheduleCurrentFinancialYear = () => {
  var today = new Date();
  var curMonth = today.getMonth();
  var financialYear = '';
  if (curMonth > 3) {
    var nextYr1 = (today.getFullYear() + 1).toString();
    financialYear =
      '1 April, ' +
      today.getFullYear().toString() +
      ' to ' +
      '30 April, ' +
      nextYr1;
  } else {
    var nextYr2 = today.getFullYear().toString();
    financialYear =
      '1 April, ' +
      (today.getFullYear() - 1).toString() +
      ' to ' +
      '30 April, ' +
      nextYr2;
  }
  return financialYear;
};
