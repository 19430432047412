import AxiosInstance from '../utils/axios_instance';
import {useQuery} from 'react-query';
import {config} from '../constants/config';
import {cacheConfig} from '../constants/query_constants';

export default function GetArchiveNews() {
  return useQuery(cacheConfig.ARCHIVE_NEWS_CACHE_KEY, async () => {
    const {data} = await AxiosInstance({
      method: 'get',
      url: `${config.BASE_API_URL}footer_pg/archieve_news.json${config.CMS_PARAMETERS}`,
    });
    return data;
  });
}
