import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ListItem, List} from '@material-ui/core';
import {Link, useLocation} from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll';
import {useSingletonApiData} from '../../store/GlobalContext/ContextStore';

const listOfDoYouWantToSaveUrls = ['/submit-resume'];

const StyledAccordion = styled(Accordion)`
  background-color: #eeeeee !important;
  color: #ffffff !important;
  font-weight: bold !important;
  &.MuiPaper-elevation1 {
    box-shadow: none !important;
    margin: 5px 0;
  }
  margin-bottom: 10px;
  border-radius: 6px !important;
  &.MuiAccordion-root.Mui-expanded {
    margin: 5px 0;
    background: linear-gradient(
      301.97deg,
      #024182 1.86%,
      rgba(2, 65, 130, 0.64) 98.56%
    ) !important;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  &.MuiAccordion-root:before {
    background-color: transparent;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400 !important;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #5b5b5b !important;
  border-radius: 6px !important;
  &.MuiAccordionSummary-root.Mui-expanded {
    background-color: transparent;
    color: #ffffff !important;
    font-weight: bold !important;
    min-height: 0px;
  }
`;
const UnderLine = styled.hr`
  color: #ffffff;
  margin: 0 !important;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px !important;
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  font-size: 14px;
  color: #ffffff;
  font-family: 'Roboto';
  background-color: transparent;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-weight: 500;
  box-shadow: 0 !important;
  padding: 0px 20px 10px 20px !important;
`;
const StyledUi = styled.ul`
  list-style: none;
  padding: 0em !important;
  margin: 0 !important;
  width: 100%;
`;
const MainDiv = styled.div`
  margin-bottom: 12px !important;
  position: sticky;
  top: ${({isTopHeaderVisible}) => (isTopHeaderVisible ? '21%' : '12%')};
  bottom: 30%;
  overflow: auto;
`;
const StyledLi = styled.li`
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 10px 12px;
`;
const StyledA = styled(Link)`
  color: #5b5b5b;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.5px;
`;
const StyledSubA = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.5px;
`;
const StyledList = styled(ListItem)`
  background-color: #eeeeee !important;
  color: #5b5b5b !important;
  justify-content: left !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-family: 'Roboto';
  padding: 20px 20px !important;
  &.MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: linear-gradient(
      301.97deg,
      #024182 1.86%,
      rgba(2, 65, 130, 0.64) 98.56%
    ) !important;
    color: #ffffff !important;
  }
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;
const StyledHtml = styled.html`
  scroll-behavior: smooth;
  cursor: pointer;
`;
const StyledMainList = styled(List)`
  padding-bottom: 0px !important;
  padding-top: 5px !important;
`;

const SideBar = ({
  link,
  isHomeLoan = true,
  data,
  isOtherLoan = true,
  otherData,
  isSamePage = false,
  isInvestor = false,
}) => {
  const {sidebarData} = useSingletonApiData();
  const [expandedPanel, setExpandedPanel] = useState(link);
  const [selected, setSelected] = useState(false);
  const [showButtonIndex, setShowButtonIndex] = useState(Number(0));
  const leaveThisPagePopupRef = React.useRef(null);
  const location = useLocation();

  const ID_VAL = 'id value';
  let id;
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const handleAccordionChange = (index) => {
    leaveThisPagePopupRef.current = null;
    const {pathname} = location;
    const fnToExecute = () => {
      window.scrollTo(0, 0);
      localStorage.removeItem(ID_VAL);
      if (expandedPanel === index) {
        setExpandedPanel('');
      } else {
        setExpandedPanel(index);
      }
    };
    if (listOfDoYouWantToSaveUrls.includes(pathname)) {
      leaveThisPagePopupRef.current = fnToExecute;
      return;
    }
    fnToExecute();
  };
  const handleClick = (e, index, homeLoan) => {
    if (isInvestor) {
      window.scrollTo(0, 0);
    }
    localStorage.removeItem(ID_VAL);
    if (!homeLoan) {
      localStorage.setItem(ID_VAL, index);
    }
    setSelected(true);
    setShowButtonIndex(Number(index));
  };

  useEffect(() => {
    leaveThisPagePopupRef.current?.();
    leaveThisPagePopupRef.current = null;
  }, [location.pathname]);

  useEffect(() => {
    if (!isHomeLoan) {
      id = localStorage.getItem(ID_VAL);
      if (id === undefined) {
        setShowButtonIndex(Number(0));
      } else {
        setShowButtonIndex(Number(id));
      }
    } else {
      localStorage.removeItem(ID_VAL);
    }
  });
  var [isTopHeaderVisible, setIsTopHeaderVisible] = React.useState(true);
  window.onscroll = function (e) {
    const headerRef = this.oldScroll > this.scrollY;
    headerRef !== isTopHeaderVisible && setIsTopHeaderVisible(headerRef);
    this.oldScroll = this.scrollY;
  };

  return (
    <MainDiv
      isTopHeaderVisible={isTopHeaderVisible}
      className={!isMobile && 'sidebar-scroll'}>
      {isHomeLoan ? (
        <>
          {sidebarData?.homeLoanList.map((el, i) => {
            return (
              <StyledAccordion
                expanded={expandedPanel === el.name}
                onChange={() => handleAccordionChange(el.name)}>
                <StyledA to={el.path}>
                  <StyledAccordionSummary>{el.subTitle}</StyledAccordionSummary>
                </StyledA>
                <StyledAccordionDetails>
                  <StyledUi>
                    <UnderLine />
                    {sidebarData?.homeLoanSubItems[i].heading.map((el, i) => (
                      <>
                        <StyledHtml>
                          <ScrollLink
                            id={i}
                            offset={-115}
                            onClick={(e) => handleClick(e, el.id, true)}
                            to={el.href}>
                            <StyledLi
                              style={
                                showButtonIndex === i
                                  ? {
                                      background: '#024182',
                                      boxShadow:
                                        '0px 10px 30px rgba(0, 0, 0, 0.15)',
                                      fontWeight: '500',
                                    }
                                  : {background: 'rgba(255, 255, 255, 0.1)'}
                              }>
                              <>{el.subTitle}</>
                            </StyledLi>
                          </ScrollLink>
                        </StyledHtml>
                      </>
                    ))}
                  </StyledUi>
                </StyledAccordionDetails>
              </StyledAccordion>
            );
          })}
        </>
      ) : (
        <>
          {isOtherLoan ? (
            <StyledColumn>
              {data.map((el) => {
                return (
                  <StyledMainList>
                    <StyledHtml>
                      {isSamePage ? (
                        <ScrollLink offset={-115} to={el.path}>
                          <StyledList
                            onClick={() => handleAccordionChange(el.name)}
                            selected={el.name === expandedPanel}
                            component="a"
                            href={el?.path}
                            button>
                            {el.subTitle}
                          </StyledList>
                        </ScrollLink>
                      ) : (
                        <StyledA to={el.path}>
                          <StyledList
                            onClick={() => handleAccordionChange(el.name)}
                            selected={el.name === expandedPanel}
                            component="a"
                            href={el?.path}
                            button>
                            {el.subTitle}
                          </StyledList>
                        </StyledA>
                      )}
                    </StyledHtml>
                  </StyledMainList>
                );
              })}
            </StyledColumn>
          ) : (
            <StyledColumn>
              {otherData.map((el, i) => {
                return (
                  <StyledAccordion
                    expanded={expandedPanel === el.name}
                    onChange={() => handleAccordionChange(el.name)}>
                    <StyledA to={el.path}>
                      <StyledAccordionSummary>
                        {el.subTitle}
                      </StyledAccordionSummary>
                    </StyledA>
                    <StyledAccordionDetails>
                      <StyledUi>
                        {el.heading.length !== 0 ? <UnderLine /> : null}
                        {el.heading.map((el, i) => (
                          <>
                            <StyledHtml>
                              {isInvestor ? (
                                <StyledSubA
                                  id={i}
                                  onClick={(e) => handleClick(e, el.id, false)}
                                  to={el.href}>
                                  <StyledLi
                                    style={
                                      showButtonIndex === i
                                        ? {
                                            background: '#024182',
                                            boxShadow:
                                              '0px 10px 30px rgba(0, 0, 0, 0.15)',
                                          }
                                        : {
                                            background:
                                              'rgba(255, 255, 255, 0.1)',
                                          }
                                    }>
                                    <>{el.subTitle}</>
                                  </StyledLi>
                                </StyledSubA>
                              ) : (
                                <ScrollLink
                                  offset={-115}
                                  id={i}
                                  onClick={(e) => handleClick(e, el.id, false)}
                                  to={el.href}>
                                  <StyledLi
                                    style={
                                      showButtonIndex === i
                                        ? {
                                            background: '#024182',
                                            boxShadow:
                                              '0px 10px 30px rgba(0, 0, 0, 0.15)',
                                          }
                                        : {
                                            background:
                                              'rgba(255, 255, 255, 0.1)',
                                          }
                                    }>
                                    <>{el.subTitle}</>
                                  </StyledLi>
                                </ScrollLink>
                              )}
                            </StyledHtml>
                          </>
                        ))}
                      </StyledUi>
                    </StyledAccordionDetails>
                  </StyledAccordion>
                );
              })}
            </StyledColumn>
          )}
        </>
      )}
    </MainDiv>
  );
};

export default SideBar;
