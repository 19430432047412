import deposit_dashboard from '../assets/images/deposit_dashboard.png';
import deposit_tds from '../assets/images/deposit_tds.png';
import apply_for_deposit from '../assets/images/apply_for_deposit.svg';
import deposit_call from '../assets/images/deposit_call.png';
import deposit_reach from '../assets/images/deposit_reach.png';
import deposit_email from '../assets/images/deposit_email.png';
// import uploadFormIcon from '../assets/images/upload_forms.png';

import lending_writetous from '../assets/images/lending_writetous.png';
import lending_pay_dues from '../assets/images/lending_pay_dues.png';
import lending_margeloans from '../assets/images/lending_margeloans.png';
import lending_roi from '../assets/images/lending_roi.png';
import lending_pmay from '../assets/images/lending_pmay.png';
import lending_managedetails from '../assets/images/lending_managedetails.png';
import lending_faqs from '../assets/images/lending_faqs.png';
import lending_apply from '../assets/images/apply_lending.png';

export const homeLoanSubItems = [
  {
    tite: 'Home Loans',
    heading: [
      { subTitle: 'Loan Features', id: 0, href: 'loan_features' },
      {
        subTitle: 'Types of Home Loan',
        id: 1,
        href: 'home_loan_types',
      },
      { subTitle: 'Interest Rate', id: 2, href: 'interest_rate' },
      {
        subTitle: 'Maximum Loan Amount Repayment Period',
        id: 3,
        href: 'loan_repayment',
      },

      { subTitle: 'Calculator', id: 4, href: 'calc_section' },
      { subTitle: 'Home Loan Documents', id: 5, href: 'card_list' },
    ],
  },
  {
    tite: 'Griha Suvidha Home Loan',
    heading: [
      { subTitle: 'Eligible Customers', id: 0, href: 'eligible_cust' },
      { subTitle: 'Processing Fee', id: 1, href: 'processing_fee' },
      { subTitle: 'Interest Rate', id: 2, href: 'interest_rate' },
      { subTitle: 'Calculator', id: 3, href: 'calc_section' },
    ],
  },
  {
    tite: 'Home Loan For Pensioners',
    heading: [
      { subTitle: 'Eligible Applicants', id: 0, href: 'eligible_app' },
      { subTitle: 'Salient Features', id: 1, href: 'salient_features' },
      { subTitle: 'Additional Benefits', id: 2, href: 'additional_benefits' },
      { subTitle: 'Interest Rate', id: 3, href: 'interest_rate' },
      { subTitle: 'Calculator', id: 4, href: 'calc_section' },
    ],
  },
  {
    tite: 'Home Renovation Loan',
    heading: [
      { subTitle: 'Loan Features', id: 0, href: 'loan_features' },
      { subTitle: 'Interest Rate', id: 1, href: 'interest_rate' },
      {
        subTitle: 'Maximum Loan Amount Repayment Period',
        id: 2,
        href: 'loan_repayment',
      },
      { subTitle: 'Calculator', id: 3, href: 'calc_section' },
    ],
  },
  {
    tite: 'Home Loan Top Up',
    heading: [
      { subTitle: 'Loan Features', id: 0, href: 'loan_features' },
      { subTitle: 'Eligible Customers', id: 1, href: 'eligible_cust' },
      { subTitle: 'Interest Rate', id: 2, href: 'interest_rate' },
      { subTitle: 'Calculator', id: 3, href: 'calc_section' },
    ],
  },
  {
    tite: 'Home Extension Loan',
    heading: [
      { subTitle: 'Loan Features', id: 0, href: 'loan_features' },
      { subTitle: 'Interest Rate', id: 1, href: 'interest_rate' },
      {
        subTitle: 'Maximum Loan Amount Repayment Period',
        id: 2,
        href: 'loan_repayment',
      },
      { subTitle: 'Calculator', id: 3, href: 'calc_section' },
    ],
  },
  {
    tite: 'Plot Loan',
    heading: [
      { subTitle: 'Types of Plot Loans', id: 0, href: 'plot_loan_types' },
      { subTitle: 'Loan Features', id: 1, href: 'loan_features' },
      { subTitle: 'Calculator', id: 2, href: 'calc_section' },
    ],
  },
  {
    tite: 'Home Construction Loan',
    heading: [
      { subTitle: 'Loan Features', id: 0, href: 'loan_features' },
      { subTitle: 'Interest Rate', id: 1, href: 'interest_rate' },
      {
        subTitle: 'Maximum Loan Amount Repayment Period',
        id: 2,
        href: 'loan_repayment',
      },
      { subTitle: 'Calculator', id: 3, href: 'calc_section' },
    ],
  },
  // {
  // tite: 'Pradhan Mantri Awas Yojana',
  // heading: [
  // {
  // subTitle: 'Key highlights of the scheme',
  // id: 0,
  // href: 'key_features',
  // },
  // {
  // subTitle: 'Eligibility Criteria',
  // id: 1,
  // href: 'eligibility_criteria',
  // },
  // {
  // subTitle: 'Illustrative Examples',
  // id: 2,
  // href: 'illustrative_example',
  // },
  // {subTitle: 'Loan Features', id: 3, href: 'loan_features'},
  // {subTitle: 'Interest Rate', id: 4, href: 'interest_rate'},
  // {
  // subTitle: 'Maximum Loan Amount Repayment Period',
  // id: 5,
  // href: 'loan_repayment',
  // },
  // {subTitle: 'Calculator', id: 6, href: 'calc_section'},
  // {subTitle: 'Home Loan Documents', id: 7, href: 'home_loan_doc'},
  // ],
  // },
  {
    tite: 'Housing Loan NRI',
    heading: [
      { subTitle: 'Loan Features', id: 0, href: 'loan_features' },
      {
        subTitle: 'Type of Home Loan for NRIs',
        id: 1,
        href: 'home_loan_type_nri',
      },
      { subTitle: 'Interest Rate', id: 2, href: 'interest_rate' },
      {
        subTitle: 'Maximum Loan Amount Repayment Period',
        id: 3,
        href: 'loan_repayment',
      },
      { subTitle: 'Calculator', id: 4, href: 'calc_section' },
      { subTitle: 'Home Loan Documents', id: 5, href: 'home_loan_doc' },
    ],
  },
  {
    tite: 'Home Loan Balance Transfer',
    heading: [
      { subTitle: 'Loan Features', id: 0, href: 'loan_features' },
      { subTitle: 'Interest Rate on Home Loan', id: 1, href: 'interest_rate' },
      {
        subTitle: 'Interest Rate on Top Up Loan',
        id: 2,
        href: 'interest_rate_on_top_up_loan',
      },
      {
        subTitle: 'Maximum Loan Amount Repayment Period',
        id: 3,
        href: 'loan_repayment',
      },
      { subTitle: 'Calculator', id: 4, href: 'calc_section' },
      { subTitle: 'Home Loan Documents', id: 5, href: 'home_loan_documents' },
    ],
  },
  {
    tite: 'Advantage Plus',
    heading: [
      { subTitle: 'Why Advantage plus', id: 0, href: 'advantage_plus' },
      { subTitle: 'Interest Rate on Home Loan', id: 1, href: 'interest_rate' },
      {
        subTitle: 'Interest Rate on Top Up Loan',
        id: 2,
        href: 'interest_rate_on_top_up_loan',
      },
      {
        subTitle: 'Maximum Loan Amount Repayment Period',
        id: 3,
        href: 'loan_repayment',
      },
      { subTitle: 'Calculator', id: 4, href: 'calc_section' },
      { subTitle: 'Home Loan Documents', id: 5, href: 'home_loan_documents' },
    ],
  },
];
export const homeLoanList = [
  { subTitle: 'Home Loans', id: 0, name: 'panel_1', path: '/housing-loan' },
  {
    subTitle: 'Griha Suvidha Home Loan',
    id: 1,
    name: 'panel_2',
    path: '/gs-home-loan',
  },
  {
    subTitle: 'Home Loan For Pensioners',
    id: 2,
    name: 'panel_3',
    path: '/pensioners-loan',
  },
  {
    subTitle: 'Home Renovation Loan',
    id: 3,
    name: 'panel_4',
    path: '/renovation-loan',
  },
  {
    subTitle: 'Home Loan Top Up',
    id: 4,
    name: 'panel_5',
    path: '/topup-loan',
  },
  {
    subTitle: 'Home Extension Loan',
    id: 5,
    name: 'panel_6',
    path: '/extension-loan',
  },
  { subTitle: 'Plot Loan', id: 6, name: 'panel_7', path: '/plot-loan' },
  {
    subTitle: 'Home Construction Loan',
    id: 7,
    name: 'panel_8',
    path: '/construction-loan',
  },
  // {
  // subTitle: 'Pradhan Mantri Awas Yojana',
  // id: 8,
  // name: 'panel_9',
  // path: '/pmay',
  // },
  {
    subTitle: 'Housing Loan NRI',
    id: 9,
    name: 'panel_10',
    path: '/nri-loan',
  },
  {
    subTitle: 'Home Loan Balance Transfer ',
    id: 10,
    name: 'panel_11',
    path: '/balance-transfer',
  },
  {
    subTitle: 'Advantage Plus ',
    id: 11,
    name: 'panel_12',
    path: '/advantage-plus',
  },
];

export const corporateList = [
  {
    subTitle: 'Corporate',
    id: 0,
    name: 'panel_1',
    path: '/corporate',
    heading: [
      { subTitle: 'Staff Quarters ', id: 0, href: 'staff_quarters' },
      { subTitle: 'Line of Credit "TO" Scheme', id: 1, href: 'credit_to' },
      {
        subTitle: 'Line of Credit "Through" Scheme',
        id: 2,
        href: 'credit_through',
      },
    ],
  },
  {
    subTitle: 'Builder/Developers ',
    id: 1,
    name: 'panel_2',
    path: '/builder-developers',
    heading: [
      { subTitle: 'Construction Finance Loan ', id: 0, href: 'finance_loan' },
      { subTitle: 'Term Loan', id: 1, href: 'term_loan' },
    ],
  },
];

export const otherLoanList = [
  {
    subTitle: 'Loan against Property',
    id: 0,
    name: 'panel_1',
    path: '/lap',
  },
  {
    subTitle: 'Loan against Securities ',
    id: 1,
    name: 'panel_2',
    path: '/las',
  },
  {
    subTitle: 'Loan to Professionals ',
    id: 2,
    name: 'panel_3',
    path: '/lps',
  },
  {
    subTitle: 'Loan against the Property for Companies ',
    id: 3,
    name: 'panel_4',
    path: '/lfc',
  },
  {
    subTitle: 'Loan against Rental Securitization ',
    id: 4,
    name: 'panel_5',
    path: '/lars',
  },
];

export const usagePolicyList = [
  {
    subTitle: 'Usage Policy',
    id: 0,
    name: 'panel_1',
    path: '/usage-policy',
  },
  {
    subTitle: 'Privacy Policy',
    id: 0,
    name: 'panel_2',
    path: '/privacy-policy',
  },
];

export const directSellingAgencyList = [
  {
    subTitle: 'Direct Selling Agency',
    id: 0,
    name: 'panel_1',
    path: '/direct-selling-agency',
  },
  {
    subTitle: 'Downloads for Business Partners',
    id: 0,
    name: 'panel_2',
    path: '/downloads-for-business-partners',
  },
];

export const officeLocator = [
  {
    subTitle: 'Corporate Office',
    id: 0,
    name: 'panel_1',
    path: '/corporate-office',
  },
  {
    subTitle: 'Registered Office',
    id: 1,
    name: 'panel_2',
    path: '/register-office',
  },
  {
    subTitle: 'Area Offices',
    id: 2,
    name: 'panel_3',
    path: '/area-office',
  },
  {
    subTitle: 'Overseas Offices',

    id: 3,
    name: 'panel_4',
    path: '/overseas-office',
  },
  {
    subTitle: 'Regional Offices',
    id: 4,
    name: 'panel_5',
    path: '/regional-office',
  },
  {
    subTitle: 'Digital Channel',
    id: 5,
    name: 'panel_6',
    path: '/digital-channel',
  },
];

export const depositList = [
  {
    subTitle: 'Public Deposits',
    id: 0,
    name: 'panel_1',
    path: '/public-deposits',
    heading: [
      { subTitle: 'Features', id: 0, href: 'features' },
      {
        subTitle: 'Cumulative Public Deposit',
        id: 1,
        href: 'cum_public_deposit',
      },
      {
        subTitle: 'Non Cumulative Public Deposit',
        id: 2,
        href: 'non_cum_public_deposit',
      },
      { subTitle: 'Auto Repayment/Renewal', id: 3, href: 'auto_repayment' },
      {
        subTitle: 'Pre-Maturity Withdrawal',
        id: 4,
        href: 'pre_mat_withdrawal',
      },
      { subTitle: 'Renewal and Repayment', id: 5, href: 'renew_repay' },
      { subTitle: 'Deduction of Income (TDS)', id: 6, href: 'income_deduct' },
      { subTitle: 'Advance Against Security', id: 7, href: 'adv_against_sec' },
      { subTitle: 'Nomination', id: 8, href: 'nomination' },
      { subTitle: 'KYC Compliance', id: 9, href: 'kyc_comp' },
      // { subTitle: "Documents Required", id: 10, href: "doc_require" },
    ],
  },
  {
    subTitle: 'Corporate Deposits',
    id: 1,
    name: 'panel_2',
    path: '/corporate-deposits',
    heading: [
      { subTitle: 'Features', id: 0, href: 'features' },
      {
        subTitle: 'Cumulative Corporate Deposit',
        id: 1,
        href: 'cum_corp_deposit',
      },
      {
        subTitle: 'Non Cumulative Corporate Deposit',
        id: 2,
        href: 'non_cum_corp_deposit',
      },
      { subTitle: 'Deduction of Income (TDS)', id: 3, href: 'income_deduct' },
      { subTitle: 'Advance Against Security', id: 4, href: 'adv_against_sec' },
      { subTitle: 'Renewal and Repayment', id: 5, href: 'renew_repay' },
      { subTitle: 'KYC Compliance', id: 6, href: 'kyc_comp' },
      { subTitle: 'Documents Required', id: 7, href: 'doc_require' },
    ],
  },
  {
    subTitle: 'Application Forms',
    id: 2,
    name: 'panel_3',
    path: '/application-form',
    heading: [],
  },
  {
    subTitle: 'Frequently Asked Questions',
    id: 3,
    name: 'panel_4',
    path: '/faq',
    heading: [],
  },
];

// export const depositList = [
// {
// subTitle: "Login for deposit holders",
// id: 0,
// name: "panel_1",
// path: "/deposit-login",
// },
// {
// subTitle: "Interest rate",
// id: 1,
// name: "panel_2",
// path: "/",
// },
// {
// subTitle: "Application form",
// id: 2,
// name: "panel_3",
// path: "/af",
// },
// {
// subTitle: "Frequently asked questions ",
// id: 3,
// name: "panel_4",
// path: "/faq",
// },
// {
// subTitle: "Renewal/Maturity",
// id: 4,
// name: "panel_5",
// path: "/lars",
// },
// ];

export const aboutUsList = [
  {
    subTitle: 'About',
    id: 0,
    name: 'panel_1',
    path: 'about_us',
  },
  {
    subTitle: 'Message from MD & CEO',
    id: 1,
    name: 'panel_2',
    path: 'message_from_md',
  },
  {
    subTitle: 'Vision, Mission & Values',
    id: 2,
    name: 'panel_3',
    path: 'vision_mission',
  },
  {
    subTitle: 'Subsidairy Companies',
    id: 3,
    name: 'panel_4',
    path: 'subsidairy_companies',
  },
  {
    subTitle: 'Board of Directors',
    id: 4,
    name: 'panel_5',
    path: 'board_director',
  },
  {
    subTitle: 'Committees and its Composition',
    id: 5,
    name: 'panel_6',
    path: 'commitees_composition',
  },
];

export const careerList = [
  {
    subTitle: 'Job Opportunities',
    id: 0,
    name: 'panel_1',
    path: '/job-opportunities',
  },
  {
    subTitle: 'Submit Resume',
    id: 1,
    name: 'panel_2',
    path: '/submit-resume',
  },
];

export const faq = [
  {
    subTitle: 'Public Deposits',
    id: 0,
    name: 'panel_1',
    path: '/public-deposits',
  },
  {
    subTitle: 'Corporate Deposits',
    id: 1,
    name: 'panel_2',
    path: '/corporate-deposits',
  },
  {
    subTitle: 'Application Forms',
    id: 2,
    name: 'panel_3',
    path: '/application-form',
  },
  {
    subTitle: 'FAQs',
    id: 3,
    name: 'panel_4',
    path: '/faq',
  },
];

export const corporateGovernanceCertificate = [
  {
    subTitle: 'Corporate Governance Certificate',
    id: 0,
    name: 'panel_1',
    path: '/corporate-governance',
    heading: [],
  },
  {
    subTitle: 'Meeting Attendance',
    id: 1,
    name: 'panel_2',
    path: '/meeting-attendance',
    heading: [],
  },
  {
    subTitle: 'Committees',
    id: 2,
    name: 'panel_3',
    path: '/committees',
    heading: [
      { subTitle: 'Audit Committee ', id: 0, href: 'audit_commitee' },
      { subTitle: 'Role', id: 1, href: 'role' },
      { subTitle: 'Powers', id: 2, href: 'powers' },
      { subTitle: 'Stakeholders Relationship', id: 3, href: 'stake_holders' },
      { subTitle: 'Executive Committee', id: 4, href: 'executive_committee' },
    ],
  },
  {
    subTitle: 'Board of Directors',
    id: 3,
    name: 'panel_4',
    path: '/director-board',
    heading: [
      { subTitle: 'Composition', id: 0, href: 'composition' },
      { subTitle: 'Name of the Directors', id: 1, href: 'name_of_directors' },
      { subTitle: 'Responsibilites', id: 2, href: 'responsibilites' },
      { subTitle: 'Board Meetings', id: 3, href: 'board_meetings' },
    ],
  },
  {
    subTitle: 'Profile of Directors',
    id: 4,
    name: 'panel_5',
    path: '/director-profile',
    heading: [],
  },
  {
    subTitle: 'Appointment letter',
    id: 5,
    name: 'panel_6',
    path: '/appointment-letter',
    heading: [],
  },
  {
    subTitle: 'Resignations',
    id: 6,
    name: 'panel_7',
    path: '/resignation-letter',
    heading: [],
  },
  {
    subTitle: 'Rating letter',
    id: 7,
    name: 'panel_8',
    path: '/nonconvertible-debenture',
    heading: [
      {
        subTitle: 'Non-Convertible Debenture',
        id: 0,
        href: '/nonconvertible-debenture',
      },
      {
        subTitle: 'Commercial Paper',
        id: 1,
        href: '/commercial-paper',
      },
      {
        subTitle: 'Fixed Deposits',
        id: 2,
        href: '/fixed-deposits',
      },
    ],
  },
];

export const investorsList = [
  {
    subTitle: 'Financial Achievements',
    id: 0,
    name: 'panel_1',
    path: '/financial-results',
    heading: [
      { subTitle: 'Financial Results', id: 0, href: '/financial-results' },
      {
        subTitle: 'Consolidated Related Party Disclosure',
        id: 1,
        href: '/consolidated-related',
      },
      {
        subTitle: 'Press Releases',
        id: 2,
        href: '/press-release',
      },
      {
        subTitle: 'Investor Update',
        id: 3,
        href: '/investor-update',
      },
      { subTitle: 'Concall Transcript', id: 4, href: '/concall-transcript' },
      { subTitle: 'Concall Audio', id: 5, href: '/concall-audio' },
      {
        subTitle: 'Subsidiary Financials',
        id: 6,
        href: '/subsidiary-financials',
      },
    ],
  },
  {
    subTitle: 'Stakeholders Information',
    id: 1,
    name: 'panel_2',
    path: '/transfers-of-shares',
    heading: [
      {
        subTitle: 'Transfer of Shares to Demat A/C of IEPF Authority',
        id: 0,
        href: '/transfers-of-shares',
      },
      {
        subTitle: 'Amendment to Transfer of Shares',
        id: 1,
        href: '/amendment-of-shares',
      },
      {
        subTitle: 'Investors Education Protection Fund',
        id: 2,
        href: '/investors-education',
      },
      {
        subTitle: 'Shareholders Relations',
        id: 3,
        href: '/shareholders-relations',
      },
      {
        subTitle: 'Shareholding Pattern',
        id: 4,
        href: '/shareholders-pattern',
      },
      { subTitle: 'Dividend', id: 5, href: '/dividend' },
      {
        subTitle: 'Listing of Equity Shares',
        id: 6,
        href: '/listing-equity-shares',
      },
      { subTitle: 'Debentures', id: 7, href: '/debentures' },
      { subTitle: 'Annual Report', id: 8, href: '/annual-report' },
      { subTitle: 'Shareholders Desk', id: 9, href: '/shareholders-desk' },
      {
        subTitle: 'Secretarial Compliance Report',
        id: 10,
        href: '/secreterial-compliance-report',
      },
      {
        subTitle: 'Annual Return as per Section 92 of Companies Act',
        id: 11,
        href: '/annual-report-companies-act',
      },
    ],
  },
  {
    subTitle: 'Announcements',
    id: 2,
    name: 'panel_3',
    path: '/board-meeting',
    heading: [
      {
        subTitle: 'Board Meeting',
        id: 0,
        href: '/board-meeting',
      },
      {
        subTitle: 'Postal Ballot',
        id: 1,
        href: '/postal-ballot',
      },
      {
        subTitle: 'Annual General Meeting',
        id: 2,
        href: '/agm',
      },
      {
        subTitle: 'Extraordinary General Meeting',
        id: 3,
        href: '/egm',
      },
      {
        subTitle: 'Corporate Announcement',
        id: 4,
        href: '/corporate-announcement',
      },
      {
        subTitle: 'Regulation Under SEBI',
        href: '/regulation-under-sebi',
        id: 5,
      },
      {
        subTitle: 'Intimation under Reg. 57(1)',
        href: '/intimation-under-reg',
        id: 6,
      },
    ],
  },
];

export const screenData = [
  {
    mainPath: 'Home',
    subPath: ['/'],
  },
  {
    mainPath: 'About Us',
    subPath: ['/about-us'],
  },
  {
    mainPath: 'Careers',
    subPath: ['/job-opportunities', '/submit-resume'],
  },
  {
    mainPath: 'Contact Us',
    subPath: ['/contact-us'],
  },
  {
    mainPath: 'Office Locator',
    subPath: [
      '/corporate-office',
      '/register-office',
      '/area-office',
      '/overseas-office',
      '/regional-office',
      '/digital-channel',
    ],
  },
  {
    mainPath: '',
    subPath: ['/write-to-us', '/call-us'],
  },
];

export const customerPortal = [
  {
    subTitle: 'Dashboard',
    id: 0,
    name: 'panel_1',
    path: '/deposits/home',
    image: deposit_dashboard,
  },
  {
    subTitle: 'TDS',
    id: 1,
    name: 'panel_2',
    path: '/deposits/tds',
    image: deposit_tds,
  },
  // {
  //   subTitle: 'Upload Forms',
  //   id: 3,
  //   name: 'panel_3',
  //   path: '/deposits/form-15G',
  //   image: uploadFormIcon,
  //   heading: [
  //     {
  //       subTitle: 'Form 15G',
  //       id: 0,
  //       path: '/deposits/form-15G',
  //     },
  //     {
  //       subTitle: 'Form 15H',
  //       id: 1,
  //       path: '/deposits/form-15H',
  //     },
  //   ],
  // },
  {
    subTitle: 'Apply for Deposit',
    id: 5,
    name: 'panel_2',
    path: '/deposits/apply-for-deposit',
    image: apply_for_deposit,
  },
  {
    subTitle: 'Call us',
    id: 2,
    name: 'panel_3',
    path: '/deposits/call-us',
    image: deposit_call,
  },
  {
    subTitle: 'Email us',
    id: 3,
    name: 'panel_4',
    path: '/deposits/email-us',
    image: deposit_email,
  },
  {
    subTitle: 'Reach us',
    id: 4,
    name: 'panel_5',
    path: '/deposits/reach-us',
    image: deposit_reach,
  },
];

export const customerPortalLending = ({ commonStrings }) => [
  {
    subTitle: 'Dashboard',
    id: 0,
    name: 'panel_1',
    path: '/lending/home',
    image: deposit_dashboard,
  },
  {
    subTitle: 'Apply Loan',
    id: 1,
    name: 'panel_2',
    path: '/lending/apply-loan',
    image: lending_apply,
  },
  {
    subTitle: 'Pay Dues Online',
    id: 2,
    name: 'panel_3',
    path: '/lending/pay-dues-online',
    image: lending_pay_dues,
  },
  // {
  //   subTitle: 'PMAY CLSS Update',
  //   id: 3,
  //   name: 'panel_4',
  //   path: '/lending/pmay-clss-update',
  //   image: lending_pmay,
  // },

  {
    subTitle: 'Change ROI',
    id: 4,
    name: 'panel_5',
    path: 'NA',
    image: lending_roi,
  },
  {
    subTitle: 'Merge Loans',
    id: 5,
    name: 'panel_5',
    path: '/lending/merge-loans',
    image: lending_margeloans,
  },

  {
    subTitle: 'Manage Details',
    id: 6,
    name: 'panel_3',
    path: '/lending/change-password',
    image: lending_managedetails,
    heading: [
      {
        subTitle: 'Change Password',
        id: 0,
        path: '/lending/change-password',
      },
      {
        subTitle: 'Update PAN Details',
        id: 1,
        path: '/lending/update_pan_details',
      },
    ],
  },

  {
    subTitle: 'Write to Us',
    id: 7,
    name: 'panel_5',
    path: '/lending/write-to-us',
    image: lending_writetous,
  },

  {
    subTitle: 'Reach Us',
    id: 8,
    name: 'panel_3',
    path: '/lending/area-office',
    image: deposit_reach,
    heading: [
      {
        subTitle: 'Area Office',
        id: 0,
        path: '/lending/area-office',
      },
      {
        subTitle: 'CRO',
        id: 1,
        path: '/lending/cro',
      },
      {
        subTitle: 'Agent',
        id: 2,
        path: '/lending/agent-reach-us',
      },
    ],
  },

  {
    subTitle: 'FAQs',
    id: 9,
    name: 'panel_5',
    path: commonStrings?.faq,
    image: lending_faqs,
  },
];

export const Activities = [
  {
    subTitle: 'Loan Information',
    id: 0,
    name: 'panel_1',
    path: '/lending/loan-information',
  },
  {
    subTitle: 'PDC/ECS',
    id: 1,
    name: 'panel_2',
    path: '/lending/pdc',
  },
  {
    subTitle: 'Loan Disbursement',
    id: 2,
    name: 'panel_3',
    path: '/lending/loan-disbursement',
  },
  {
    subTitle: 'Repayment Schedule',
    id: 3,
    name: 'panel_4',
    path: '/lending/repayment-schedule',
  },
  {
    subTitle: 'Repay Certificate',
    id: 4,
    name: 'panel_5',
    path: '/lending/repay-certificate',
  },
  {
    subTitle: 'Repayment Ledger',
    id: 5,
    name: 'panel_6',
    path: '/lending/repayment-ledger',
  },
  {
    subTitle: 'NACH Cancellation',
    id: 6,
    name: 'panel_7',
    path: '/lending/nach-mandate',
  },
  // {
  //   subTitle: 'Disbursement Request',
  //   id: 7,
  //   name: 'panel_8',
  //   path: '/lending/home',
  // },
];
