
import { useMutation } from 'react-query';
import { config } from '../constants/config';
import AxiosInstance from '../utils/axios_instance';

export default function useTickerBar() {
    return useMutation(async (payload) => {
        const { data } = await AxiosInstance({
            method: 'post',
            url: config.OAUTH_API_URL + 'getscrollerdetails',
            data: payload,
            headers: {
                apikey: `${config.APIGEE_API_KEY}`,
            },
        });
        console.log("tickerdata", data)
        return data;
    });
}
