import styled from 'styled-components';
import parse from 'html-react-parser';
import {Button} from 'react-scroll';

const MainWrapper = styled.div`
  margin: 10px 0px;
`;
const HeaderText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 52px;
  display: flex;
  align-items: center;
  text-align: start;
  font-feature-settings: 'cpsp' on;
  color: #000000;
  margin: 10px 0px;
  @media ${(props) => props.theme.mediaQueries.small} {
    font-size: 36px;
    line-height: 46px;
  }
`;

const HeaderImage = styled.div`
  margin-top: -10%;
  position: absolute;
  right: 0;
  padding-right: 8rem;

  @media ${(props) => props.theme.mediaQueries.small} {
    padding-right: 0rem;
    width: 25%;
    margin-top: -22%;
  }
`;

const OfferImage = styled.img`
  width: 100%;
`;

const HeaderTextCenter = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-feature-settings: 'cpsp' on;
  color: #000000;
  margin: 10px 0px;
`;

const SubHeaderText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
`;

const SubHeaderNoDescpText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 5%;
`;

const Description = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #5b5b5b;
  margin: 2% 0% 1% 0%;
`;

function MainSectionHeader({
  data,
  id,
  cls,
  isCenter,
  offerImg,
  headerContainerStyle,
  hasLink = false,
  path,
  linkName,
}) {
  return (
    <MainWrapper>
      {data &&
        data.map((el) => (
          <>
            {offerImg ? (
              <HeaderImage>
                <OfferImage src={offerImg} alt="offer" />
              </HeaderImage>
            ) : null}
            <div style={headerContainerStyle || {}}>
              {isCenter ? (
                <HeaderTextCenter id={id}>{el.title}</HeaderTextCenter>
              ) : (
                <>
                  <HeaderText id={id}>{el.title}</HeaderText>
                  {hasLink && (
                    <a target={'blank'} href={path}>
                      {linkName}
                    </a>
                  )}
                </>
              )}
            </div>
            {el.subtitle ? (
              el.description ? (
                <SubHeaderText> {parse(el.subtitle)}</SubHeaderText>
              ) : (
                <SubHeaderNoDescpText> {el.subtitle}</SubHeaderNoDescpText>
              )
            ) : null}

            {el.description ? (
              <Description className="black_descp_b">
                {parse(el.description)}
              </Description>
            ) : null}
          </>
        ))}
    </MainWrapper>
  );
}

export default MainSectionHeader;
