import React from 'react'
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useSelectedMultilingualLanguage } from '../../store/GlobalContext/ContextStore';
import Marquee from "react-fast-marquee";

const ScrollingText = styled.div`
.overlay{
  padding-right: 150px;
}
a:link {
  overflow: visible;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  overflow: visible;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  overflow: visible;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  overflow: visible;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  background-color: transparent;
  text-decoration: none;
}
overflow: visible;
margin-top: 0%;
width:100%;
margin-bottom: 0%;
height: 25px;
color: white;
display: flex;
align-items: center;
background: rgb(93,134,176);
background: linear-gradient(91deg, rgba(93,134,176,1) 0%, rgba(75,120,167,1) 25%, rgba(31,86,145,1) 56%, rgba(28,84,143,1) 71%, rgba(20,78,139,1) 100%, rgba(18,78,139,1) 100%);
@media only screen and (max-width: 950px) {
  width:100%;
  height: 55px;
  display: flex;
  align-items: center;
    
  }
  @media (min-width: 414) and (max-width: 896) {
    width:100%;
    height: 55px;
    display: flex;
    align-items: center;
  
    }
  }
 
}
`;
const Tickerbar = ({ data }) => {
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;

  return (
    <>
      {data?.data?.scrollerDetails?.length > 0 ? (
        <ScrollingText isMobile >
          <Marquee pauseOnHover='true' speed='100' gradient='false' gradientColor='[0,0,0]' gradientWidth='100'>

            {data.data.scrollerDetails.map(scroller => (
              <p>
                <a className='overlay' target="_blank" href={scroller.page_url}>{scroller.scroller_text} </a>
              </p>
            ))}

          </Marquee>
        </ScrollingText>
      ) : null}
    </>
  )
}

export default Tickerbar