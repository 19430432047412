import styled from 'styled-components';

const HeaderText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  color: #000000;
  font-family: Roboto;
  text-align: start;
  display: flex;
  align-items: center;
  font-feature-settings: 'cpsp' on;
  color: #000000;
  margin: 20px 0px;
  @media ${(props) => props.theme.mediaQueries.small} {
    font-size: 36px;
    line-height: 46px;
    margin: 20px 0px;
  }
`;

const WithSubtitleText = styled.h3`
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  color: #000000;
  font-family: Roboto;
  margin-bottom: 0;
  @media ${(props) => props.theme.mediaQueries.small} {
    font-size: 36px;
    line-height: 46px;
  }
`;

function SectionHeaderText({title, id, isSubtitle = false}) {
  return isSubtitle ? (
    <WithSubtitleText id={id}>{title} </WithSubtitleText>
  ) : (
    <HeaderText id={id}>{title}</HeaderText>
  );
}

export default SectionHeaderText;
