import React from "react";
import styled from "styled-components";
import { strings } from "../../constants/strings";

const ErrorTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 52px;
  display: flex;
  align-items: center;
  font-feature-settings: "cpsp" on;
  color: #000000;
  margin: 30px 0px 20px 0px;
  justify-content: center;
`;
const ErrorSubtitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
  color: #000000;
  text-align: center;
`;

function ErrorText() {
  return (
    <div>
      <ErrorTitle>{strings.SOMETHING_WENT_WRONG}</ErrorTitle>
      <ErrorSubtitle>{strings.TRY_RELOAD_MESSAGE}</ErrorSubtitle>
    </div>
  );
}

export default ErrorText;
