import AxiosInstance from '../utils/axios_instance';
import {useQuery} from 'react-query';
import {config} from '../constants/config';
import {cacheConfig} from '../constants/query_constants';

export default function useGetPublicHolidays() {
  return useQuery(cacheConfig.GOVERNANCE_CERTIFICATE_CACHE_KEY, async () => {
    const {data} = await AxiosInstance({
      method: 'get',
      url: `${config.BASE_API_URL}footer_pg/public_holiday.json${config.CMS_PARAMETERS}`,
    });

    return data;
  });
}
