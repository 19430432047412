export const config = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  CMS_PARAMETERS: process.env.REACT_APP_CMS_PARAMETERS,
  BASE_BACKEND_URL: process.env.REACT_APP_BASE_BACKEND_URL,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  OAUTH_API_URL: process.env.REACT_APP_OAUTH_API_URL,
  OAUTH_KEY: process.env.REACT_APP_OAUTH_KEY,
  OAUTH_SECRET: process.env.REACT_APP_OAUTH_SECRET,
  OAUTH_PARAM: process.env.REACT_APP_OAUTH_PARAM,
  EMBEDDED: process.env.REACT_APP_EMBEDDED,
  APIGEE_API_KEY: process.env.REACT_APP_API_KEY,

  // BASE_API_URL: "http://liccorpstaging.niveussolutions.com/api/",
  // CMS_PARAMETERS: "?crafterSite=lichfl-corporate-website-cms",
  // BASE_BACKEND_URL: "http://liccorpstaging.niveussolutions.com",
  // GOOGLE_API_KEY: "AIzaSyABj0M2rHREKjTA7bfYyFpS-P__epgF09o",
  // OAUTH_API_URL: "https://uat-api.lichousing.com/",
  // OAUTH_KEY: "DkiEkanDyiGTWUIvjLGBJFvQY5wc8A8t89HNUSpCG88kdGP7",
  // OAUTH_SECRET:
  //   "WIYuGpj84bopClJSiOfHABoatGMV8LG7w2f9AAJsKqjWgW8oboHoJLVMGpAMwIex",
  // OAUTH_PARAM: "grant_type=client_credentials",
  // EMBEDDED: "&embedded=true",
  // APIGEE_API_KEY: "Lv2Goizd6B3evgO2miH0gSGZzGE27f0HRPHFM4qZjUvOWm2E",
};
