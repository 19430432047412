import styled from 'styled-components';
import Eclipse from '../../assets/images/eclipse.png';

const Box = styled.div`
  position: absolute;
  //width: 100%;
  right: 0;
  justify-content: flex-end;
  display: flex;
  z-index: -2;
  top: 65%;
`;

function BackgroundCircle() {
  return (
    <Box>
      <img src={Eclipse} alt="eclipse" />
    </Box>
  );
}

export default BackgroundCircle;
