import React from "react";
import Loader from "../../components/UI/loader";
import styled from "styled-components";
import GetLhplr from "../../apiRequests/lhplr_api";
import ErrorText from "../../components/UI/error_text";
import MainSectionHeader from "../../components/UI/main_section_header";
import MetaTag from "../../components/seo_component/metaTag";
import Slider from "../../components/slider_component";
import QueryLink from "../../components/UI/query_link";
import LicHeaderLogo from "../../assets/images/lic_header_logo.png";
import parse from "html-react-parser";
import BackgroundCircle from "../../components/UI/background_circle";

const MainWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CardWrapper = styled.div`
  display: flex;
  margin: 0 var(--horizontal-spacing);
`;

const MainContainer = styled.div`
  background: linear-gradient(180deg, #f9f9f9 0%, rgba(238, 238, 238, 0) 100%);
  padding: 40px 20px;
  margin-bottom: 100px;
  width: 90%;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
  }
`;
const ModalImageContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`;

const SubtitleContainer = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.5px;
`;
const DescriptionContainer = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
  width: 90%;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
  }
`;

export default function Lhplr() {
  const { error, isLoading, data } = GetLhplr();
  const isDesktop = window.matchMedia("(min-width: 1150px)").matches
    ? true
    : false;
  if (isLoading) return <Loader loading={true} />;
  if (error) return <ErrorText />;
  const bread_crumb_data = [{ title: "LHPLR" }];

  return (
    <div>
      <Slider
        data={data.banner}
        isHome={false}
        bread_crumb_data={bread_crumb_data}
      />
      <MetaTag
        title={"Policy Codes"}
        description={
          "Finance Company, Home Loan Providers In India,Dubai,Kuwait, Home Loans – With you for your dream home.Find easy Housing Loan for your needs from LIC HFL. Get lowest interest rates and fulfill the dream of owning your dream home."
        }
      />
      <CardWrapper>
        <MainWrapper>
          <MainSectionHeader data={data.title} />
          <MainContainer>
            <ModalImageContainer>
              <img
                alt="LIC LOGO"
                src={LicHeaderLogo}
                resizeMode={"center"}
                style={{
                  width: isDesktop ? 160 : 130,
                  height: 60,
                  overflow: "visible",
                  objectFit: "contain",
                  zIndex: 994400000,
                }}
              />
            </ModalImageContainer>
            {data.message.map((el) => (
              <>
                <SubtitleContainer>{parse(`${el.subtitle}`)}</SubtitleContainer>
                <DescriptionContainer>{el.description}</DescriptionContainer>
              </>
            ))}
          </MainContainer>
        </MainWrapper>
      </CardWrapper>
      {isDesktop ? <BackgroundCircle /> : null}
      <QueryLink data={data.queryServices} />
    </div>
  );
}
