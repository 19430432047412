import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import parse from "html-react-parser";

const MainGrid = styled(Grid)`
  min-height: 20vh;
  width: 100%;
  padding: 2rem;
  @media ${(props) => props.theme.mediaQueries.small} {
    overflow-x: scroll;
  }

  @media ${(props) => props.theme.mediaQueries.medium} {
    padding: 0rem;
    padding-top: 2rem;
  }
`;
const MainDiv = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  @media ${(props) => props.theme.mediaQueries.medium} {
    flex-direction: column;
  }
`;
const TableMainHeader = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  padding-bottom: 5%;
`;
const TableSubHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #5b5b5b;
  text-align: center;
  padding-bottom: 2%;
  padding-top: 2%;
`;
const UnderLine = styled.hr`
  margin: 0 !important;
  width: 100%;
  height: 0.2px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 2px !important;
`;

const MUIBasicTable = ({ table, isDeposit = false }) => {
  const [tbData, setTbData] = useState([]);
  const [colData, setColData] = useState([]);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [rerenderFlag, setRerenderFlag] = useState(() => false);
  useEffect(() => {
    dataConstruction();
  }, [table]);

  const dataConstruction = () => {
    if (rerenderFlag) {
      //to avoid the re-render of the component because of parent component
      return;
    }
    var tempTableData = [];
    var tempColumnData = [];
    var tempTitle = [];
    var tempHeader = [];

    setColData([]);
    Object.entries(table).map(([keyName, value], index) => {
      if (keyName.includes("table")) {
        if (!isDeposit) {
          Object.entries(value.header[0]).map(([key, val]) => {
            tempColumnData.push({
              field: key,
              title: val,
              cellStyle: {
                borderBottomWidth: "1px",
                borderBlockColor: "#9a9a9a",
                borderBottomStyle: "solid",
              },
            });
          });
        } else {
          Object.entries(value.header[0]).map(([key, val]) => {
            tempColumnData.push({
              field: key,
              title: val,
              cellStyle: {
                borderBottomWidth: "1px",
                borderBlockColor: "#9a9a9a",
                borderBottomStyle: "solid",
                colour: "red",
                width: "50%",
              },
            });
          });
        }
        if (value?.title) {
          Object.entries(value?.title[0]).map(([key, val]) => {
            if (key === "title") {
              tempTitle.push(val);
            }
            if (key === "description") {
              tempHeader.push(val);
            }
          });
          setTitle(tempTitle);
          setDescription(tempHeader);
        }
        setColData(colData.concat(tempColumnData));

        tempColumnData = [];
        if (value.content) {
          const sortData = value.content.sort((a, b) =>
            a.columnPosition > b.columnPosition ? 1 : -1
          );

          tempTableData.push(sortData);
        }
      }
    });
    setTbData(tempTableData);
    setRerenderFlag(true);
  };

  const tableRender = (el, index) => {
    return (
      <div style={{ width: "100%" }}>
        <MainGrid item direction='column'>
          {title[index] || description[index] ? (
            <div style={{ minWidth: "25rem" }}>
              <TableMainHeader>{title[index]}</TableMainHeader>
              <UnderLine />
              <TableSubHeader>{description[index]}</TableSubHeader>
              <UnderLine />
            </div>
          ) : null}

          <MaterialTable
            style={{ boxShadow: "none", minWidth: "25rem" }}
            data={el}
            components={{
              Toolbar: () => null,
            }}
            columns={colData}
            options={{
              headerStyle: {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "14px",
                letterSpacing: "0.5px",
                borderBottomWidth: "1px",
                borderBlockColor: "#9a9a9a",
                borderBottomStyle: "solid",
                color: isDeposit ? "#5B5B5B" : "black",
                zIndex: 0,
              },
              search: false,
              sorting: false,
              filtering: false,
              paging: false,
              showTitle: false,
              rowStyle: {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "14px",
                letterSpacing: "0.5px",
              },
            }}
          />
        </MainGrid>
      </div>
    );
  };
  return (
    <MainDiv>
      {tbData !== [] && colData !== []
        ? tbData.map((el, index) => {
            return tableRender(el, index);
          })
        : null}
    </MainDiv>
  );
};

export default MUIBasicTable;
