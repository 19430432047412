import React from 'react';
import styled from 'styled-components';

import BackgroundCircle from '../../UI/background_circle';
import QueryLink from '../../UI/query_link';
import SideBar from '../../UI/side_Bar';
import Slider from '../../slider_component';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TextWrapper = styled.section`
  display: flex;
  flex-direction: row;
  margin: 2rem var(--horizontal-spacing);

  @media ${props => props.theme.mediaQueries.medium} {
    flex-direction: column-reverse;
  }
`;

const SubWrapper = styled.section`
  width: 80%;

  @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
  }
`;

const WrapLeft = styled.section`
  width: 20%;
  margin-right: 2rem;

  @media ${props => props.theme.mediaQueries.medium} {
    margin-right: 0rem;
    margin-top: 2rem;
    width: 100%;
  }
`;

function SidebarLayout({
  sliderDataProps,
  children,
  queryLinkData,
  sideBarData,
  link,
  isHomeLoan = false,
  isOtherLoan = true,
  isInvestor = false,
  otherData,
  isSamePage = false,
}) {
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;

  return (
    <Wrapper>
      <Slider {...sliderDataProps} />
      <TextWrapper>
        {!isMobile && (
          <WrapLeft>
            <SideBar
              data={sideBarData}
              isHomeLoan={isHomeLoan}
              isOtherLoan={isOtherLoan}
              isInvestor={isInvestor}
              link={link}
              otherData={otherData}
              isSamePage={isSamePage}
            />
          </WrapLeft>
        )}
        <SubWrapper>{children}</SubWrapper>
        {!isMobile && <BackgroundCircle />}
        {isMobile && (
          <WrapLeft>
            <SideBar
              data={sideBarData}
              isHomeLoan={isHomeLoan}
              isInvestor={isInvestor}
              link={link}
              otherData={otherData}
              isSamePage={isSamePage}
              isOtherLoan={isOtherLoan}
            />
          </WrapLeft>
        )}
      </TextWrapper>
      {queryLinkData && <QueryLink data={queryLinkData} />}
    </Wrapper>
  );
}

export default SidebarLayout;
