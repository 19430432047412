import React, { useState, useEffect } from "react";
import styled from "styled-components";

import DesktopMenu from "../layout/desktop/desktop_menu";
import MobileMenu from "../layout/mobile/mobile_menu";

const StyledHeader = styled.header`
  width: 100%;
  max-width: 100vw;
  top: 0;
  z-index: 10000;
`;

const Navbar = () => {
  const [isMobile, setisMobile] = useState(null);

  // Change navbar content accordingly
  const changeMobile = () => {
    window.matchMedia("(max-width: 950px)").matches
      ? setisMobile(true)
      : setisMobile(false);
  };

  // Event listener on resize, so when it change we check o remove desktop menu/mobile menu
  // Better than CSS media query because we dont keep both DOM nodes
  useEffect(() => {
    changeMobile();
    window.addEventListener("resize", changeMobile);
    return () => window.removeEventListener("resize", changeMobile);
  }, []);

  return isMobile ? <MobileMenu /> : <DesktopMenu />;
};

export default Navbar;
