import {useQuery} from 'react-query';
import {config} from '../constants/config';
import {cacheConfig} from '../constants/query_constants';
import AxiosInstance from '../utils/axios_instance';

export default function useCustomerLoginConstant() {
  return useQuery(cacheConfig.CUSTOMER_LOGIN_CACHE_KEY, async () => {
    const {data} = await AxiosInstance({
      method: 'get',
      url:
        config.BASE_API_URL +
        'customerlogin/customer_login.json' +
        config.CMS_PARAMETERS,
    });
    return data;
  });
}
