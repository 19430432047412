import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {scrollToTop} from '../../utils/app_utils';
import useStickyMenuRight from '../../apiRequests/sticky_side_menu_api';
import {config} from '../../constants/config';

const ListImage = styled.img`
  //padding-top: 1%;
`;

const ListText = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  font-family: 'Roboto';
  margin: 10% 0%;
`;

const SideSection = styled.section`
  margin-top: 38%;
  margin-left: auto;
  width: 75%;
  background: linear-gradient(
    302deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  );
  box-shadow: 4px 9px 52px rgba(0, 0, 0, 0.08);
  flex-direction: column;
  border-radius: 10px;
  padding: 15% 0%;
`;

const LineSection = styled.hr`
  color: #e2e2e2;
  opacity: 0.5;
  width: 70%;
  margin-bottom: 20%;
`;

const ImageTextBox = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

function SideMenuSection() {
  const {isLoading, error, data} = useStickyMenuRight();
  const extractSideMenuData = (data) => {
    const sortData = data.sort((a, b) => (a.position > b.position ? 1 : -1));
    return sortData.map((val, i) => {
      const imageUrl = `${config.BASE_BACKEND_URL}${val.src}${config.CMS_PARAMETERS}`;
      return (
        <div>
          <ImageTextBox
            to={val.externalLink ? '/' : val.link}
            onClick={() => {
              if (val?.externalLink) window.open(val?.externalLink);
              scrollToTop();
            }}>
            <ListImage src={imageUrl} alt="side menu" />
            <ListText>{val.name}</ListText>
          </ImageTextBox>
          {val.name !== 'Write to us' ? <LineSection /> : null}
        </div>
      );
    });
  };
  return (
    <div>
      <SideSection>{data && extractSideMenuData(data.menu)}</SideSection>
    </div>
  );
}

export default SideMenuSection;
