import styled from 'styled-components';
import {Button} from '@material-ui/core';

export const MainWrapper = styled.div`
  padding: 30px 0px 60px 0px;
  border-radius: 10px;
`;

export const MainTitle = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  color: #00529c;
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-left: 20px;
  }
`;

export const RowView = styled.div`
  flex-direction: row;
  display: flex;
  @media ${(props) => props.theme.mediaQueries.small} {
    flex-direction: column;
  }
`;

export const MarginRightView = styled.div`
  flex: 1;
  margin-left: 20px;
`;

export const BlueUnderlineView = styled.div`
  border-radius: 4px;
  margin-top: -3px;
  border: 2px solid #00529c;
  width: 180px;
  margin-left: 20px;
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-left: 0px;
    width: 215px;
  }
`;

export const GreyLine = styled.div`
  border-radius: 1px;
  border: 1px solid #eaeaea;
`;
export const DropdownStyle = styled.div`
  margin: 10px 20px 0px 20px;
`;
export const GreyUnderLine = styled.div`
  border-radius: 1px;
  margin-top: 10px;
  border: 1px solid #eaeaea;
`;

export const SubTitleText = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  align-self: center;
  margin-left: 20px;
`;

export const MarginTopView = styled.div`
  margin-top: 30px;
  text-align: center;
`;

export const ButtonSubTitle = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #5b5b5b;
  margin: 20px 20px 0px 20px;
`;

export const TextHeading = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  flex: 1;
  margin: 10px 0px;
  text-align: center;
`;
export const TextMessage = styled.div`
  color: #5b5b5b;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  flex: 1;
  margin: 12px 0px;
  text-align: center;
`;
export const StyledButton = styled(Button)`
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  );
  border-radius: 6px !important;
  color: #ffffff !important;
  align-self: center;
  width: 198px;
  height: 50px;
  text-transform: capitalize !important;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 150px;
    height: 40px;
  }
`;

export const TableRowView = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  background: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 80vh;
    overflow-x: scroll;
    margin: 20px 20px 0px 20px;
    background: #ffffff;
    box-shadow: 4px 10px 40px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin: 20px;
  }
`;

export const MainContentWrapper = styled.div`
  background: #ffffff;
  padding: 20px 0px;
  border-radius: 10px;
  margin-top: 20px;
  @media ${(props) => props.theme.mediaQueries.small} {
    margin: 20px 20px 0px 20px;
    background: #ffffff;
    box-shadow: 4px 10px 40px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin: 20px;
  }
`;
