import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin: 10rem 0;
  height: 100;
`;

function Loader({loading, isCustomerPortal = false, color}) {
  return (
    <LoaderWrapper>
      <FadeLoader
        color={color ? color : 'var(--primary)'}
        loading={loading}
        css={{
          height: 100,
          width: '100%',
          left: isCustomerPortal ? '50%' : 0,
        }}
        size={100}
      />
    </LoaderWrapper>
  );
}

export default Loader;
