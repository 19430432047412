import React from 'react';
import ErrorText from '../../components/UI/error_text';
import Loader from '../../components/UI/loader';
import styled from 'styled-components';
import MainSectionHeader from '../../components/UI/main_section_header';
import MetaTag from '../../components/seo_component/metaTag';
import Slider from '../../components/slider_component';
import useGetCareers from '../../apiRequests/careers_api';
import {config} from '../../constants/config';
import parse from 'html-react-parser';
import SidebarLayout from '../../components/layout/sidebar_child_layout/sidebar_layout';
import MUIAccordian from '../../components/UI/mUIAccordian';
import {useSingletonApiData} from '../../store/GlobalContext/ContextStore';

const MainPadding = styled.div`
  // padding: 3rem;
  @media ${(props) => props.theme.mediaQueries.medium} {
    padding: 5%;
    text-align: left;
  }
`;

const ImageDiv = styled.div`
  display: block;
  margin: auto;
  text-align: center;
`;

const GreyTextDiv = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 178% */

  text-align: center;
  letter-spacing: 0.5px;

  color: #5b5b5b;
`;

const Careers = () => {
  const {sidebarData} = useSingletonApiData();
  const {isLoading, error, data} = useGetCareers();
  console.log(data, 'data');
  if (isLoading) return <Loader loading={true} />;
  if (error) return <ErrorText />;
  const bread_crumb_data = [{title: 'Careers'}];
  const imageUrl =
    data && data.length > 0 && data?.data[0]?.image
      ? `${config.BASE_BACKEND_URL}${data?.data[0]?.image}${config.CMS_PARAMETERS}`
      : null;
  return (
    <div id="EMI_Relief_div">
      {/* <Slider
        data={data.banner}
        isHome={false}
        bread_crumb_data={bread_crumb_data}
      /> */}
      <SidebarLayout
        sliderDataProps={{
          data: data?.banner,
          isHome: false,
          bread_crumb_data: bread_crumb_data,
        }}
        // queryLinkData={data.queryServices}
        sideBarData={sidebarData?.careerList}
        link={'panel_1'}
        isHomeLoan={false}
        isOtherLoan={true}>
        <MetaTag
          title={'Loan Under Rental Securitization'}
          description={
            'Finance Company, Home Loan Providers In India,Dubai,Kuwait, Home Loans – With you for your dream home.Find easy Housing Loan for your needs from LIC HFL. Get lowest interest rates and fulfill the dream of owning your dream home.'
          }
        />
        <MainPadding>
          <MainSectionHeader data={data.message} />
          {/* <MUIAccordian id="griha_suvidha_home_loan" data={data.loantypes} /> */}
        </MainPadding>
      </SidebarLayout>
    </div>
  );
};

export default Careers;
