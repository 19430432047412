import React from 'react';

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import globe from '../../assets/images/globe.svg';
import {strings} from '../../constants/strings';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const SelectContainer = styled.div`
  dispaly: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const VerticalSeparator = styled.span`
  dispaly: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0.7rem 0.5rem 0 0;
  @media ${(props) => props.theme.mediaQueries.small} {
    // margin: 6px 0px 0px 50px;
  }
`;

const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiMenu-paper': {
      marginLeft: '-10px !important',
      width: '120px',
    },

    '& .MuiPaper-elevation8': {},

    '& ul': {
      backgroundColor: '#fff',
      color: '#5b5b5b',
    },
    '& li': {
      fontSize: '15px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiListItem-root.Mui-selected, & .MuiListItem-root .Mui-selected:hover':
      {
        color: '#000',
        fontWeight: 500,
        backgroundColor: '#fff',
      },
  },

  formControl: {
    //width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    //maxHeight: 200,
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}));

export default function LanguageDropdown({
  dropdownTitle,
  dropdownValues,
  dropdownAction,
  selectedValue,
  disabled,
  category,
}) {
  const classes = useStyles();
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const isDesktop = window.matchMedia('(min-width: 1150px)').matches
    ? true
    : false;
  return (
    <SelectContainer>
      {isDesktop ? (
        <StyledImage src={globe} />
      ) : (
        <div style={{position: 'absolute'}}>
          <StyledImage src={globe} />
        </div>
      )}
      <FormControl sx={{}} style={{width: isMobile ? '50px' : '80px'}}>
        <Select
          style={{marginTop: '0.3rem'}}
          disableUnderline
          value={isDesktop ? selectedValue : ''}
          onChange={dropdownAction}
          displayEmpty
          IconComponent={(props) => (
            <div
              style={{
                bottom: '5px',
                position: 'absolute',
              }}
              className={`material-icons ${props.className}`}>
              <ArrowDropDownIcon />
            </div>
          )}
          inputProps={{'aria-label': 'Without label'}}
          MenuProps={{
            classes: {paper: classes.select},
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}>
          {dropdownValues?.map((el) => (
            <MenuItem value={el.value}>
              {selectedValue === el.value ? (
                <b
                  style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}>
                  {el.label}
                </b>
              ) : (
                <b
                  style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontSize: '15px',
                    fontWeight: 'normal',
                    textAlign: 'center',
                  }}>
                  {el.label}
                </b>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectContainer>
  );
}
