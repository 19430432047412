import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styled from 'styled-components';

const StyledMenu = styled.div`
  width: 185px;
  border-radius: 6px;
  color: white;
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.87) 98.56%
  );

  boxshadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-top: -8px;
`;

export default function ButtonDropdown({ dataList, placeholder }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [activeCustomer, setActiveCustomer] = React.useState(
    placeholder || dataList[0],
  );
  const ref = useRef();
  const handleClick = () => {
    setAnchorEl((prev) => !prev);
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (anchorEl && ref.current && !ref.current.contains(e.target)) {
        setAnchorEl(null);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [anchorEl]);
  const handleClose = (val) => {
    val.name !== activeCustomer && setActiveCustomer(val.name);
    //handle callback here
    window.open(val.link, '_blank');
    setAnchorEl(null);
    setActiveCustomer(placeholder);
  };

  const isDesktop = window.matchMedia('(min-width: 1150px)').matches
    ? true
    : false;

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={
          open ? (
            <KeyboardArrowUpIcon style={{ fontSize: 26 }} />
          ) : (
            <KeyboardArrowDownIcon style={{ fontSize: 26 }} />
          )
        }
        style={{
          zIndex: 100000,
          width: isDesktop ? 185 : 155,
          height: 28,
          color: 'white',
          background: '#265B93',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontWeight: 'bold',
          textTransform: 'none',
          fontSize: isDesktop ? 13 : 10,
          borderRadius: 6,
          alignSelf: 'center',
          textAlign: 'center',
          background: 'linear-gradient(301.97deg, #024182 1.86%, rgba(2, 65, 130, 0.64) 98.56%)',
        }}>
        {activeCustomer}
      </Button>
      {open && (
        <StyledMenu
          style={{
            position: 'absolute',
            left: 0,
            // background: 'linear-gradient(301.97deg, #024182 1.86%, rgba(2, 65, 130, 0.64) 98.56%)',
          }}>
          <Divider
            variant="middle"
            style={{
              background: 'white',
              height: 9,
              margin: '0 15px',
              zIndex: 1000000,
            }}
          />
          {dataList.map((el) => (
            <MenuItem
              style={{
                fontSize: 13,
                letterSpacing: 0.5,
                fontWeight: 400,
                padding: '0px 16px',
                margin: '5px 0px',

              }}
              onClick={() => handleClose(el)}>
              {el.text}
            </MenuItem>
          ))}
        </StyledMenu>
      )}
    </div>
  );
}
