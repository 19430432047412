import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const MainContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  width: auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  padding: 10px;
  justify-content: space-between;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const CardWrapper = styled.div`
  min-height: 100px;
  background: #ffffff;
  box-shadow: 4px 9px 47px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TextNumber = styled.div`
  position: absolute;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 58px;
  z-index: 1;
  color: #ffcb08;
  top: -20px;
`;

const TextBlockContainer = styled.div`
  //   padding: 2rem 0.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: "center";
  color: #000000;
`;

const TextLine = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #000000;
  width: 100%;
  margin: 0px;
`;

const PopupListItemCard = ({ data }) => {
  let sortedData = data.sort((a, b) => {
    return a.id - b.id;
  });

  return (
    <MainContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CardContainer>
            <CardWrapper>
              <TextNumber>{data[0].card1heading}</TextNumber>
              <TextBlockContainer>
                <TextLine>{data[0].card1description}</TextLine>
              </TextBlockContainer>
            </CardWrapper>
          </CardContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardContainer>
            <CardWrapper>
              <TextNumber>{data[0].card2heading}</TextNumber>
              <TextBlockContainer>
                <TextLine>{data[0].card2description}</TextLine>
              </TextBlockContainer>
            </CardWrapper>
          </CardContainer>
        </Grid>
      </Grid>
    </MainContainer>

    // <MainContainer>
    //   {data.map((el) => {
    //     return (
    //       <CardContainer>
    //         <CardWrapper>
    //           <TextPercentage>{el.id}</TextPercentage>
    //           <TextBlockContainer>
    //             <TextLineOne>{el.description}</TextLineOne>
    //           </TextBlockContainer>
    //         </CardWrapper>
    //       </CardContainer>
    //     );
    //   })}
    // </MainContainer>
  );
};

export default PopupListItemCard;
