import React, {useState} from 'react';
import styled from 'styled-components';
import useGetFooterDetails from '../../apiRequests/footer_content_api';
import TwitterLogo from '../../assets/images/twitter.png';
import YouTubeLogo from '../../assets/images/youtube.png';
import {Link} from 'react-router-dom';
import {scrollToTop} from '../../utils/app_utils';
import {config} from '../../constants/config';
import PopupCards from '../UI/popup_cards';

const Box = styled.div`
  background: #ffffff;
  position: relative;
  bottom: 0;
  padding: 0 var(--horizontal-spacing);
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding-top: 2%;
  padding-bottom: 2%;
  border-bottom: 2px solid #eaeaea;
  @media ${(props) => props.theme.mediaQueries.small} {
    flex-direction: column;
  }
`;

const ContainerFullWidth = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding: 1% 0;
  @media ${(props) => props.theme.mediaQueries.small} {
    flex-direction: column;
  }
`;

const ContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  border-top: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  padding-top: 2%;
  padding-bottom: 2%;
  @media ${(props) => props.theme.mediaQueries.small} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: ${({width}) => (width ? width : '20%')};
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
    text-align: center;
  }
`;

const ColumnFullWidth = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  color: #5b5b5b;
`;

const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 20%;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
    text-align: center;
  }
`;

const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
  margin-top: 12%;
  margin-bottom: 10%;

  @media ${(props) => props.theme.mediaQueries.small} {
    margin-top: 9%;
    margin-bottom: 6%;
  }
`;

const SubTitle = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  color: #5b5b5b;
  line-height: 24px;

  margin-top: 2%;
  margin-bottom: 2%;
  text-decoration: none;

  :hover {
    color: var(--primary);
  }
`;

const CopyRightText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  color: #5b5b5b;
`;

const SocialMediaSec = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 22%;
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-right: 5%;
    margin-left: 5%;
  }
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
  }
`;
const StyledIcon = styled.a`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

function FooterContent(props) {
  const {isLoading, error, data: footerContent} = useGetFooterDetails();
  const [modalOpen, setModalOpen] = useState(false);

  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue,
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
  const extractIconData = (iconData) => {
    const sortData = iconData.sort((a, b) =>
      a.columnPosition > b.columnPosition ? 1 : -1,
    );
    return sortData.map((val, i) => {
      const imageUrl = `${config.BASE_BACKEND_URL}${val.iconUI}${config.CMS_PARAMETERS}`;
      return (
        <StyledIcon href={val.link} target="_blank" rel="noreferrer">
          <img src={imageUrl} alt="logo" />
        </StyledIcon>
      );
    });
  };

  const onClosePopup = () => {
    setModalOpen(false);
  };

  const onOpenPopup = () => {
    setModalOpen(true);
  };

  const listFooterContent = (val) => {
    return Object.values(val).map((key, objVal) => {
      const url =
        key.link?.includes('.pdf') === true
          ? `${config.BASE_BACKEND_URL}${key.link}${config.CMS_PARAMETERS}`
          : key.link;

      return (
        <>
          {key.link?.includes('#popup') === true ? (
            <SubTitle to={'#popup'} onClick={() => onOpenPopup()}>
              {key.subtitle ? key.subtitle : key.title}
            </SubTitle>
          ) : (
            <SubTitle
              to={{pathname: url ? url : '#'}}
              target={url?.includes('http') === true ? '_blank' : ''}
              onClick={
                url?.includes('http') === true ? null : () => scrollToTop()
              }>
              {key.subtitle ? key.subtitle : key.title}
            </SubTitle>
          )}
        </>
      );
    });
  };

  const extractData = (footerObj) => {
    const sortData = footerObj.sort((a, b) =>
      a.columnPosition > b.columnPosition ? 1 : -1,
    );
    const grpdArr = groupBy(sortData, 'columnPosition');

    return Object.entries(grpdArr).map(([key, sortColData]) => {
      const val = sortColData.sort((a, b) =>
        a.position > b.position ? 1 : -1,
      );

      return (
        <Column width={val[0].width}>
          {val[0].title && val[0].subtitle ? (
            <Title>{val[0].title}</Title>
          ) : null}
          {listFooterContent(val)}
        </Column>
      );
    });
  };

  const extractCopyRightData = (copyRightData) => {
    return Object.values(copyRightData).map(([key, val]) => {
      return (
        <CopyRightText>
          {key.copyWriteMsg ? key.copyWriteMsg : ''}
        </CopyRightText>
      );
    });
  };

  return (
    <>
      {footerContent ? (
        <>
          <PopupCards
            isModalOpen={modalOpen}
            onClosePopup={() => onClosePopup()}
            data={footerContent.footerPopup}
          />
          <Box>
            <ContainerTop>
              {extractData(footerContent.footerTopBar)}
              <Column></Column>
              <Column></Column>
            </ContainerTop>
            <Container>
              {extractData(footerContent.footer)}
              <ColumnRight>
                <Title>{footerContent.socialIconsHeading}</Title>
                <SocialMediaSec>
                  {extractIconData(footerContent.socialIcons)}
                </SocialMediaSec>
              </ColumnRight>
            </Container>
            <ContainerFullWidth>
              <ColumnFullWidth>
                {extractCopyRightData(footerContent.copyWriteMsg)}
              </ColumnFullWidth>
            </ContainerFullWidth>
          </Box>
        </>
      ) : null}
    </>
  );
}

export default FooterContent;
