export const strings = {
  SOMETHING_WENT_WRONG: 'Something went wrong',
  TRY_RELOAD_MESSAGE: 'Please try reloading the page',
  NO_DATA_FOUND: 'No records found',
  CLICK_HERE: 'Click here',
  WEB_EXTENSION: 'php',
  PDF_EXTENSION: 'pdf',
  MP3_EXTENSION: 'mp3',
  HTTPS_EXTENSION: 'https',
  EMI_CALCULATOR: 'EMI Calculator',
  LOAN_ELIGIBILITY_CALCULATOR: 'Loan Eligibility Calculator',
  MOBILE_WIDTH: '(max-width: 950px)',
  META_TAG_DESCRIPTION:
    'Finance Company, Home Loan Providers In India,Dubai,Kuwait, Home Loans – With you for your dream home.Find easy Housing Loan for your needs from LIC HFL. Get lowest interest rates and fulfill the dream of owning your dream home.',

  //routes
  LOAN_INFORMATION_ROUTE: 'loan-information',
  PDC_ECS_ROUTE: 'pdc',
  REPAYMENT_SCHEDULE_ROUTE: 'repayment-schedule',
  REPAYMENT_LEDGER: 'repayment-ledger',
  REPAY_CERTIFICATE: 'repay-certificate',
  LOAN_DISBURSEMENT: 'loan-disbursement',

  //api expiry
  API_SESSION_EXPIRED_EVENT: 'api_Session_Expired_Event',
  NACH_CANCELLATION: 'nach-mandate',

  //Module constant
  WEB_LENDING: 'WEB-LENDING',
  WEB_CHANNEL: 'WEB',

  EXCEED_FILE_SIZE_MESSAGE: 'Please upload an image less than 10 MB',
  VALID_IMAGE_FORMAT_MESSAGE: 'Please upload image files only',

  //MultiLingual
  SELECTED_LANGUAGE: 'SELECTED_LANGUAGE',
  ENGLISH_LANGUAGE: 'eng',
  HINDI_LANGUAGE: 'hin',
};
