import InputAdornment from '@material-ui/core/InputAdornment';

export const initialEmiSliderJSON = ({commonStrings}) => [
  {
    apiKey: 'loanAmountRequired',
    maxLength: 10,
    textInputStyle: {
      style: {
        textAlign: 'left',
      },
    },
    inputFieldStyle: {paddingLeft: '18px'},
    inputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <div style={{color: 'black'}}>{commonStrings.rupeeString}</div>
        </InputAdornment>
      ),
    },
  },
  {
    apiKey: 'repaymentTermRequired',
    maxLength: 3,
    textInputStyle: {
      style: {
        textAlign: 'right',
        marginRight: '5px',
      },
    },
    inputFieldStyle: {paddingRight: '30px'},
    inputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <div
            style={{
              color: 'black',
            }}>
            {commonStrings.months}
          </div>
        </InputAdornment>
      ),
    },
  },
  {
    apiKey: 'interestRate',
    maxLength: 5,
    textInputStyle: {
      style: {
        textAlign: 'right',
        marginRight: '5px',
      },
    },
    isDecimalPresent: true,
    inputFieldStyle: {paddingRight: '40px'},
    inputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <div style={{color: 'black'}}>%</div>
        </InputAdornment>
      ),
    },
  },
];

export const initialLoanEligibilitySliderJSON = ({commonStrings}) => [
  {
    id: 0,
    apiKey: 'grossMonthlyIncome',
    maxLength: 7,
    textInputStyle: {
      style: {
        textAlign: 'left',
      },
    },
    inputFieldValidation: ({currentSliderVal, curSliderState}) => {
      const secondSliderVal = curSliderState.find((el) => el.id === 1).value;
      const newV = (currentSliderVal - secondSliderVal) / 2;

      // console.log(currentSliderVal, newV, "1111", secondSliderVal);

      const callbacks = [{idToSet: 4, valToSet: Math.max(0, Math.round(newV))}];

      // //TODO: Bug in Old website
      // if (secondSliderVal > currentSliderVal) {
      //   callbacks.push({ idToSet: 1, valToSet: currentSliderVal - 10_000 });
      // }

      return {
        valToSet:
          currentSliderVal - secondSliderVal > 10_000
            ? currentSliderVal
            : secondSliderVal + 10_000,
        callbacks,
      };
    },

    inputFieldStyle: {paddingLeft: '18px'},
    inputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <div style={{color: 'black'}}>{commonStrings.rupeeString}</div>
        </InputAdornment>
      ),
    },
  },
  {
    id: 1,
    apiKey: 'totalCurrentEmi',
    maxLength: 6,
    textInputStyle: {
      style: {
        textAlign: 'left',
        // marginRight: "5px",
      },
    },
    inputFieldValidation: ({currentSliderVal, curSliderState}) => {
      const firstSliderVal = curSliderState.find((el) => el.id === 0).value;
      const temp = Number(firstSliderVal - 10_000);
      // console.log(currentSliderVal, "222", temp);
      //set id 4
      function setMonthlySpare(val) {
        const newV = (firstSliderVal - val) / 2;
        const callbacks = [
          {idToSet: 4, valToSet: Math.max(0, Math.round(newV))},
        ];
        return callbacks;
      }
      //

      if (currentSliderVal > temp) {
        return {
          valToSet: temp,
          callbacks: setMonthlySpare(temp),
        };
      }

      return {
        valToSet: currentSliderVal,
        callbacks: setMonthlySpare(currentSliderVal),
      };
    },
    inputFieldStyle: {paddingLeft: '18px'},
    inputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <div
            style={{
              color: 'black',
            }}>
            {commonStrings.rupeeString}
          </div>
        </InputAdornment>
      ),
    },
  },
  {
    id: 2,
    apiKey: 'interestRate',
    maxLength: 5,
    textInputStyle: {
      style: {
        textAlign: 'right',
        marginRight: '5px',
      },
    },
    isDecimalPresent: true,
    inputFieldStyle: {paddingRight: '40px'},
    inputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <div style={{color: 'black'}}>%</div>
        </InputAdornment>
      ),
    },
  },
  {
    id: 3,
    apiKey: 'loanTerm',
    maxLength: 3,
    textInputStyle: {
      style: {
        textAlign: 'right',
        marginRight: '5px',
      },
    },
    inputFieldStyle: {paddingRight: '30px'},
    inputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <div
            style={{
              color: 'black',
            }}>
            {commonStrings.months}
          </div>
        </InputAdornment>
      ),
    },
  },
  {
    id: 4,
    apiKey: 'howMuchLoanCanYouSpare',
    allowSpecialChars: true,
    maxLength: 6,
    textInputStyle: {
      style: {
        textAlign: 'left',
        // marginRight: "5px",
      },
    },
    inputFieldValidation: ({currentSliderVal, curSliderState}) => {
      const firstSliderVal = curSliderState.find((el) => el.id === 0).value;
      const secondSliderVal = curSliderState.find((el) => el.id === 1).value;
      const I2 = (firstSliderVal - secondSliderVal) / 2;
      // console.log(currentSliderVal, "4444", I2);
      if (currentSliderVal > I2) {
        return {
          valToSet: Math.max(0, Math.round(I2)),
          callbacks: [],
        };
      }

      return {
        valToSet: Math.max(0, Math.round(currentSliderVal)),
        callbacks: [],
      };
    },
    inputFieldStyle: {paddingLeft: '18px'},
    inputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <div
            style={{
              color: 'black',
            }}>
            {commonStrings.rupeeString}
          </div>
        </InputAdornment>
      ),
    },
  },
];

export const homeLoanOfferSliderJSON = [
  {
    apiKey: 'chooseLoanAmount',
    maxLength: 12,
    textInputStyle: {
      style: {
        textAlign: 'left',
      },
    },
    inputFieldStyle: {paddingLeft: '18px'},
    inputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <div
            style={{
              color: 'black',
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: 18,
              lineHeight: 35,
            }}>
            ₹
          </div>
        </InputAdornment>
      ),
    },
  },
];

// export const emiChartData = [
//   {label: 'Monthly EMI', value: 'Rs. 84,988'},
//   {label: 'Total Interest', value: 'Rs. 10,95,500'},
//   {label: 'Total Payment', value: 'Rs. 50,99,291'},
// ];

// export const loanEligibilityChartData = [
//   {label: 'Loan Amount', value: 'Rs. 25,90,585'},
//   {label: 'Monthly EMI', value: 'Rs. 55,988'},
//   {label: 'Property Cost', value: 'Rs. 32,38,321'},
// ];
