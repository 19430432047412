import React, {useEffect, Suspense} from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import {CaptchaProvider} from 'react-custom-captcha';

import Layout from './components/layout/layout';
import Loader from './components/UI/loader';

import withClearCache from './clear_cache';
import '../src/styles/common.css';
import lhplr from './pages/general/lhplr';
import PublicHolidays from './pages/general/footer/public_holiday';
import PemiCovid from './pages/general/footer/pemi_covid';
import Careers from './pages/general/careers';
import CustomerEducation from './pages/Home/CustomerEducation';
import NotFoundPage from './pages/not_found_page/not_found_page';
import Home from './pages/Home/home';
import ReactGA from 'react-ga4';
import ProtectedDepositPortalRoute from './utils/protected_deposit_portal_route';
const SubmitResume = React.lazy(() => import('./pages/general/submit_resume'));
const JobOpportunities = React.lazy(() =>
  import('./pages/general/job_opportunities'),
);

ReactGA.initialize('G-HHJGEF18ZD');

const HomeLoanNri = React.lazy(() =>
  import('./pages/home_loans/home_loan_nri'),
);
const HomeConstructionLoan = React.lazy(() =>
  import('./pages/home_loans/home_construction_loan'),
);
const PMAwasYojna = React.lazy(() =>
  import('./pages/home_loans/pm_awas_yojna'),
);
const AdvantagePlus = React.lazy(() =>
  import('./pages/special_offers/advantage_plus'),
);
const HouseRenovationScreen = React.lazy(() =>
  import('./pages/home_loans/home_renovation_loans'),
);
const HomeExtension = React.lazy(() =>
  import('./pages/home_extension_loan/home_extension'),
);
const HousingLoan = React.lazy(() => import('./pages/home_loans/housing_loan'));
const GrihaSuvidhaHomeLoan = React.lazy(() =>
  import('./pages/home_loans/griha_suvidha_home_loan'),
);
const HomeLoanBalanceTransfer = React.lazy(() =>
  import('./pages/home_loans/home_loan_balance_transfer'),
);
const TopOnHomeLoans = React.lazy(() =>
  import('./pages/home_loans/top_home_loan'),
);
const BuilderAndDeveloperScreen = React.lazy(() =>
  import('./pages/corporate_project_loans/builder_and_developers'),
);
const PlotLoan = React.lazy(() => import('./pages/plot_loans/plot_loan'));
const CorporateScreen = React.lazy(() =>
  import('./pages/corporate_project_loans/corporate_screen'),
);
const LoanAgainstRental = React.lazy(() =>
  import('./pages/other_loans/loan_against_rental'),
);
const LoanAgainstSecurities = React.lazy(() =>
  import('./pages/other_loans/loan_against_securities'),
);
const ApplicationForm = React.lazy(() =>
  import('./pages/deposits/application_form'),
);
const LoanAgainstPropertyBusiness = React.lazy(() =>
  import('./pages/other_loans/loan_against_property_business'),
);
const LoanAgainstPropertyCompanies = React.lazy(() =>
  import('./pages/other_loans/loan_against_property_companies'),
);
const LoginDepositHolders = React.lazy(() =>
  import('./pages/deposits/login_deposit_holders'),
);
const CorporateOfficeScreen = React.lazy(() =>
  import('./pages/office_locator/corporate_office'),
);
const AboutUs = React.lazy(() => import('./pages/general/about_us'));
const InterestRate = React.lazy(() => import('./pages/deposits/interest_rate'));
const GrievanceReaddressalMechanism = React.lazy(() =>
  import('./pages/general/footer/grievance_readdressal_mechanism'),
);
const LoanAgainstProfessional = React.lazy(() =>
  import('./pages/other_loans/loan_against_professionals'),
);
const DigitalChannel = React.lazy(() =>
  import('./pages/office_locator/digital_channel'),
);
const ContactUs = React.lazy(() => import('./pages/general/contact_us'));
const ComprehensiveNoticeBoard = React.lazy(() =>
  import('./pages/Home/comprehensive_notice_board'),
);
const FinancialResults = React.lazy(() =>
  import('./pages/investor/financial_results'),
);
const PressRelease = React.lazy(() => import('./pages/investor/press_release'));
const ConsolidatedRelated = React.lazy(() =>
  import('./pages/investor/consolidated_related'),
);
const ConcallTranscript = React.lazy(() =>
  import('./pages/investor/concall_transcript'),
);
const CorporateAnnouncement = React.lazy(() =>
  import('./pages/investor/corporate_announcement'),
);
const SecretarialCompliance = React.lazy(() =>
  import('./pages/investor/secretarial_compliance_report'),
);
const AnnualReturnCompaniesAct = React.lazy(() =>
  import('./pages/investor/annual_return_companies_act'),
);
const ConcallAudio = React.lazy(() => import('./pages/investor/concall_audio'));
const WriteToUs = React.lazy(() => import('./pages/general/write_to_us'));
const UsagePolicy = React.lazy(() =>
  import('./pages/footer_pages/usage_policy'),
);
const PrivacyPolicy = React.lazy(() =>
  import('./pages/footer_pages/privacy_policy'),
);
const RegulatoryDisclosure = React.lazy(() =>
  import('./pages/general/footer/regulatory_disclosure'),
);
const PublicDisclosure = React.lazy(() =>
  import('./pages/general/footer/public_disclosure'),
);
const HomeLoanPensioners = React.lazy(() =>
  import('./pages/home_loans/home_loan_pensioners'),
);
const Faq = React.lazy(() => import('./pages/deposits/faq'));
const RegisterOfficeScreen = React.lazy(() =>
  import('./pages/office_locator/register_office'),
);
const NonConvertibleDebenture = React.lazy(() =>
  import('./pages/general/footer/non_convertible_debenture'),
);
const DirectSellingAgency = React.lazy(() =>
  import('./pages/footer_pages/direct_selling_agency'),
);
const DownloadForBusiness = React.lazy(() =>
  import('./pages/footer_pages/download_for_business'),
);
const HomeLoanEnquiry = React.lazy(() =>
  import('./pages/general/home_loan_enquiry'),
);
const OverseasOfficeScreen = React.lazy(() =>
  import('./pages/office_locator/overseas_office'),
);
const RegionalOfficeScreen = React.lazy(() =>
  import('./pages/office_locator/regional_office'),
);
const AreaOfficeScreen = React.lazy(() =>
  import('./pages/office_locator/area_office'),
);
const CorporateGovernanceCertificate = React.lazy(() =>
  import('./pages/general/footer/corporate_governance_certificate'),
);
const ProfileOfDirectors = React.lazy(() =>
  import('./pages/general/footer/profile_of_directors'),
);
const BoardofDirectors = React.lazy(() =>
  import('./pages/general/footer/board_of_directors'),
);
const Downloads = React.lazy(() => import('./pages/footer_pages/downloads'));
const Tenders = React.lazy(() => import('./pages/footer_pages/tenders'));
const CallUs = React.lazy(() => import('./pages/general/call_us'));
const PropertyAuction = React.lazy(() =>
  import('./pages/footer_pages/properties_for_auction'),
);
const CoronaGuidanceSupport = React.lazy(() =>
  import('./pages/general/footer/corona_guidance_support'),
);
const PolicyAndCodes = React.lazy(() =>
  import('./pages/general/footer/policy_and_codes'),
);
const ListingEquityShares = React.lazy(() =>
  import('./pages/investor/listing_equity_shares'),
);
const Dividend = React.lazy(() => import('./pages/investor/dividend'));
const Debentures = React.lazy(() =>
  import('./pages/general/footer/debentures'),
);
const FixedDeposits = React.lazy(() =>
  import('./pages/footer_pages/fixed_deposits'),
);
const CommercialPaper = React.lazy(() =>
  import('./pages/footer_pages/commercial_paper'),
);
const ExtraOrdinaryGeneralMeeting = React.lazy(() =>
  import('./pages/investor/extra_ordinary_general_meeting'),
);
const InvestorsEducationProtectionFund = React.lazy(() =>
  import('./pages/investor/investors_education_protection_fund'),
);
const AmendmentOfShares = React.lazy(() =>
  import('./pages/investor/amendment_of_shares'),
);
const TransfersOfShares = React.lazy(() =>
  import('./pages/investor/transfers_of_shares'),
);
const CommiteesScreen = React.lazy(() =>
  import('./pages/general/footer/commitees'),
);
const PostalBallot = React.lazy(() => import('./pages/investor/postal_ballot'));
const ArchiveNews = React.lazy(() =>
  import('./pages/general/footer/archieve_news'),
);
const InvestorUpdate = React.lazy(() =>
  import('./pages/investor/investor_update'),
);
const ShareHoldersRelations = React.lazy(() =>
  import('./pages/investor/shareholders_relations'),
);
const ShareHoldersPattern = React.lazy(() =>
  import('./pages/investor/shareholders_patterns'),
);
const ShareholdersDesk = React.lazy(() =>
  import('./pages/investor/shareholders_desk'),
);
const AnnualGeneralMeeting = React.lazy(() =>
  import('./pages/investor/annual_general_meeting'),
);
const EmiCalculatorScreen = React.lazy(() =>
  import('./pages/footer_pages/calculators/emi_calculator_screen'),
);
const IntimationUnderSebi = React.lazy(() =>
  import('./pages/investor/intimation_under_reg57'),
);
const RegulationUnderSebi = React.lazy(() =>
  import('./pages/investor/regulation_46_and_62'),
);
const AnnualReport = React.lazy(() => import('./pages/investor/annual_report'));
const ResignationLetter = React.lazy(() =>
  import('./pages/general/footer/resignation_letter'),
);
const AppointmentLetter = React.lazy(() =>
  import('./pages/general/footer/appointment_letter'),
);
const SubsidaryFinancials = React.lazy(() =>
  import('./pages/investor/subsidiary_financials'),
);
const BoardMeeting = React.lazy(() => import('./pages/investor/board_meeting'));

const MeetingAttendence = React.lazy(() =>
  import('./pages/general/footer/meeting_attendence'),
);
const SubsidiaryAccordian = React.lazy(() =>
  import('./components/UI/subsidiary_accordian'),
);
const PublicDeposits = React.lazy(() =>
  import('./pages/deposits/public_deposits'),
);
const GoogleSearch = React.lazy(() => import('./pages/search/googleSearch'));
const CorporateDeposits = React.lazy(() =>
  import('./pages/deposits/corporate_deposits'),
);

const ReachToUsScreen = React.lazy(() =>
  import('./pages/customer_portal/reach_us'),
);
const LendingWriteToUs = React.lazy(() =>
  import('./pages/customer_portal_lending/home/lending_write_to_us'),
);
const RepaymentLedger = React.lazy(() =>
  import('./pages/customer_portal_lending/home/repayment_ledger'),
);
const MergeLoans = React.lazy(() =>
  import('./pages/customer_portal_lending/home/merge_loans'),
);
const LoanDisbursement = React.lazy(() =>
  import('./pages/customer_portal_lending/home/loan_disbursement'),
);
const applyForDeposit = React.lazy(() =>
  import('./pages/customer_portal/home/apply_for_deposit'),
);
const changePassword = React.lazy(() =>
  import('./pages/customer_portal/home/change_password'),
);
const UpdatePanDetails = React.lazy(() =>
  import('./pages/customer_portal/home/update_PanDetails'),
);
const callToUsScreen = React.lazy(() =>
  import('./pages/customer_portal/home/call_us'),
);

const EmailToUsScreen = React.lazy(() =>
  import('./pages/customer_portal/depositEmailUs'),
);

const TdsScreen = React.lazy(() =>
  import('./pages/customer_portal/tds_screen'),
);

const Form_15G = React.lazy(() =>
  import('./pages/customer_portal/home/Form_15G.js'),
);
const Form_15H = React.lazy(() =>
  import('./pages/customer_portal/home/Form_15H.js'),
);

const Dashboard = React.lazy(() =>
  import('./pages/customer_portal/home/dashboard'),
);

const CustomerDepositLogin = React.lazy(() =>
  import('./pages/customer_portal/customer_deposits/deposit_login'),
);
const CustomerLendingLogin = React.lazy(() =>
  import('./pages/customer_portal/customer_lending/lending_login'),
);
const Lhplr = React.lazy(() => import('./pages/general/lhplr'));

const ClearCacheComponent = withClearCache(MainApp);

// const Home = React.lazy(() => import('./pages/Home/home'));

const PayDuesOnlineScreen = React.lazy(() =>
  import('./pages/customer_portal/customer_lending/pay_dues_online'),
);
const PaymentPayU = React.lazy(() =>
  import('./pages/customer_portal/customer_lending/payment_payu'),
);

const PaymentHDFC = React.lazy(() =>
  import('./pages/customer_portal/customer_lending/payment_hdfc'),
);

const Response = React.lazy(() =>
  import('./pages/customer_portal/customer_lending/response'),
);

const LendingReachUsScreen = React.lazy(() =>
  import('./pages/customer_portal/customer_lending/lending_area_office'),
);

const LendingDashboard = React.lazy(() =>
  import('./pages/customer_portal_lending/home/dashboard'),
);

const LoanInformation = React.lazy(() =>
  import('./pages/customer_portal_lending/home/loanInformation'),
);
const PDC = React.lazy(() =>
  import('./pages/customer_portal_lending/home/pdc'),
);
const NACHCancellation = React.lazy(() =>
  import('./pages/customer_portal_lending/home/NACHCancellation'),
);
const CRO = React.lazy(() =>
  import('./pages/customer_portal_lending/home/cro'),
);
const RepaymentSchedule = React.lazy(() =>
  import('./pages/customer_portal_lending/home/repayment_schedule'),
);

const ApplyLoanLending = React.lazy(() =>
  import('./pages/customer_portal_lending/home/applyLoanLending'),
);
const PmayClssUpdate = React.lazy(() =>
  import('./pages/customer_portal_lending/home/pmayClassUpdate'),
);
const AgentReachUs = React.lazy(() =>
  import('./pages/customer_portal_lending/home/reachUsAgent'),
);
const RepayCertificate = React.lazy(() =>
  import('./pages/customer_portal_lending/home/repay_certificate'),
);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const CHAT_MESSAGE = 'message_value';
  useEffect(() => {
    window.scrollTo(0, 0);
    // localStorage.setItem(CHAT_MESSAGE, null);
  }, []);

  return (
    <BrowserRouter>
      <CaptchaProvider>
        <Layout>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/housing-loan" component={HousingLoan} />
              <Route
                exact
                path="/pensioners-loan"
                component={HomeLoanPensioners}
              />
              <Route
                exact
                path="/construction-loan"
                component={HomeConstructionLoan}
              />
              <Route
                exact
                path="/renovation-loan"
                component={HouseRenovationScreen}
              />
              <Route exact path="/nri-loan" component={HomeLoanNri} />
              <Route
                exact
                path="/gs-home-loan"
                component={GrihaSuvidhaHomeLoan}
              />
              <Route
                exact
                path="/balance-transfer"
                component={HomeLoanBalanceTransfer}
              />
              {/* <Route exact path="/pmay" component={PMAwasYojna} /> */}
              <Route exact path="/advantage-plus" component={AdvantagePlus} />
              <Route
                exact
                path="/builder-developers"
                component={BuilderAndDeveloperScreen}
              />
              <Route exact path="/plot-loan" component={PlotLoan} />
              <Route exact path="/corporate" component={CorporateScreen} />
              <Route exact path="/extension-loan" component={HomeExtension} />
              <Route exact path="/topup-loan" component={TopOnHomeLoans} />
              <Route
                exact
                path="/lap"
                component={LoanAgainstPropertyBusiness}
              />
              <Route exact path="/lars" component={LoanAgainstRental} />
              <Route exact path="/las" component={LoanAgainstSecurities} />
              <Route
                exact
                path="/lfc"
                component={LoanAgainstPropertyCompanies}
              />
              <Route exact path="/lps" component={LoanAgainstProfessional} />
              <Route exact path="/interest-rate" component={InterestRate} />
              <Route
                exact
                path="/application-form"
                component={ApplicationForm}
              />
              <Route
                exact
                path="/deposit-login"
                component={LoginDepositHolders}
              />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/write-to-us" component={WriteToUs} />
              <Route exact path="/call-us" component={CallUs} />
              <Route
                exact
                path="/apply-for-deposit"
                component={applyForDeposit}
              />

              <Route
                exact
                path="/comprehensive-notice-board"
                component={ComprehensiveNoticeBoard}
              />
              <Route
                exact
                path="/customer-education"
                component={CustomerEducation}
              />

              <Route exact path="/faq" component={Faq} />
              <Route
                exact
                path="/corporate-governance"
                component={CorporateGovernanceCertificate}
              />
              <Route exact path="/careers" component={Careers} />
              <Route
                exact
                path="/job-opportunities"
                component={JobOpportunities}
              />
              <Route exact path="/submit-resume" component={SubmitResume} />
              <Route
                exact
                path="/corporate-deposits"
                component={CorporateDeposits}
              />
              <Route exact path="/public-deposits" component={PublicDeposits} />
              <Route exact path="/public-holidays" component={PublicHolidays} />
              <Route exact path="/emi-relief" component={PemiCovid} />
              <Route
                exact
                path="/director-profile"
                component={ProfileOfDirectors}
              />
              <Route
                exact
                path="/director-board"
                component={BoardofDirectors}
              />
              <Route
                exact
                path="/corporate-office"
                component={CorporateOfficeScreen}
              />
              <Route
                exact
                path="/home-loan-enquiry"
                component={HomeLoanEnquiry}
              />
              <Route exact path="/digital-channel" component={DigitalChannel} />

              <Route
                exact
                path="/financial-results"
                component={FinancialResults}
              />
              <Route
                exact
                path="/shareholders-relations"
                component={ShareHoldersRelations}
              />
              <Route
                exact
                path="/shareholders-pattern"
                component={ShareHoldersPattern}
              />
              <Route
                exact
                path="/subsidiary-financials"
                component={SubsidaryFinancials}
              />
              <Route
                exact
                path="/subsidiary-financial-details"
                component={SubsidiaryAccordian}
              />
              <Route exact path="/postal-ballot" component={PostalBallot} />
              <Route
                exact
                path="/financial-results"
                component={FinancialResults}
              />
              <Route exact path="/press-release" component={PressRelease} />
              <Route
                exact
                path="/consolidated-related"
                component={ConsolidatedRelated}
              />

              <Route
                exact
                path="/egm"
                component={ExtraOrdinaryGeneralMeeting}
              />
              <Route
                exact
                path="/transfers-of-shares"
                component={TransfersOfShares}
              />
              <Route
                exact
                path="/amendment-of-shares"
                component={AmendmentOfShares}
              />
              <Route
                exact
                path="/investors-education"
                component={InvestorsEducationProtectionFund}
              />
              <Route
                exact
                path="/shareholders-desk"
                component={ShareholdersDesk}
              />
              <Route exact path="/annual-report" component={AnnualReport} />

              <Route exact path="/agm" component={AnnualGeneralMeeting} />
              <Route exact path="/investor-update" component={InvestorUpdate} />
              <Route exact path="/board-meeting" component={BoardMeeting} />
              <Route
                exact
                path="/concall-transcript"
                component={ConcallTranscript}
              />
              <Route
                exact
                path="/corporate-announcement"
                component={CorporateAnnouncement}
              />
              <Route
                exact
                path="/regulation-under-sebi"
                component={RegulationUnderSebi}
              />
              <Route
                exact
                path="/intimation-under-reg"
                component={IntimationUnderSebi}
              />

              <Route
                exact
                path="/secreterial-compliance-report"
                component={SecretarialCompliance}
              />
              <Route
                exact
                path="/annual-report-companies-act"
                component={AnnualReturnCompaniesAct}
              />

              <Route
                exact
                path="/corporate-announcement"
                component={CorporateAnnouncement}
              />
              <Route
                exact
                path="/secreterial-compliance-report"
                component={SecretarialCompliance}
              />
              <Route
                exact
                path="/annual-report-companies-act"
                component={AnnualReturnCompaniesAct}
              />

              <Route exact path="/concall-audio" component={ConcallAudio} />

              <Route
                exact
                path="/register-office"
                component={RegisterOfficeScreen}
              />
              <Route
                exact
                path="/overseas-office"
                component={OverseasOfficeScreen}
              />
              <Route
                exact
                path="/regional-office"
                component={RegionalOfficeScreen}
              />
              <Route exact path="/area-office" component={AreaOfficeScreen} />
              <Route exact path="/digital-channel" component={DigitalChannel} />
              <Route
                exact
                path="/grievance-mechanism"
                component={GrievanceReaddressalMechanism}
              />
              <Route
                exact
                path="/regulatory-disclosure"
                component={RegulatoryDisclosure}
              />
              <Route
                exact
                path="/liquidity-profile"
                component={PublicDisclosure}
              />
              <Route exact path="/usage-policy" component={UsagePolicy} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/policy-codes" component={PolicyAndCodes} />
              <Route
                exact
                path="/corona-guidance-support"
                component={CoronaGuidanceSupport}
              />
              <Route
                exact
                path="/nonconvertible-debenture"
                component={NonConvertibleDebenture}
              />
              <Route
                exact
                path="/direct-selling-agency"
                component={DirectSellingAgency}
              />
              <Route
                exact
                path="/downloads-for-business-partners"
                component={DownloadForBusiness}
              />
              <Route
                exact
                path="/listing-equity-shares"
                component={ListingEquityShares}
              />
              <Route exact path="/dividend" component={Dividend} />
              <Route exact path="/archive-news" component={ArchiveNews} />
              <Route exact path="/debentures" component={Debentures} />
              <Route exact path="/downloads" component={Downloads} />
              <Route exact path="/tender-notice" component={Tenders} />
              <Route
                exact
                path="/property-auction"
                component={PropertyAuction}
              />
              <Route exact path="/fixed-deposits" component={FixedDeposits} />
              <Route
                exact
                path="/commercial-paper"
                component={CommercialPaper}
              />
              <Route exact path="/committees" component={CommiteesScreen} />
              <Route
                exact
                path="/emi-calculator"
                render={(props) => (
                  <EmiCalculatorScreen activeTab={0} {...props} />
                )}
              />
              <Route
                exact
                path="/loan-eligibility"
                render={(props) => (
                  <EmiCalculatorScreen activeTab={1} {...props} />
                )}
              />

              <Route exact path="/fixed-deposits" component={FixedDeposits} />
              <Route
                exact
                path="/commercial-paper"
                component={CommercialPaper}
              />
              <Route exact path="/archive-news" component={ArchiveNews} />
              <Route exact path="/committees" component={CommiteesScreen} />
              <Route
                exact
                path="/resignation-letter"
                component={ResignationLetter}
              />
              <Route
                exact
                path="/appointment-letter"
                component={AppointmentLetter}
              />
              <Route
                exact
                path="/meeting-attendance"
                component={MeetingAttendence}
              />
              <Route exact path="/search" component={GoogleSearch} />
              <Route exact path="/lhplr" component={Lhplr} />
              {/* <Route
                exact
                path="/deposits/login"
                component={CustomerDepositLogin}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/home"
                component={Dashboard}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/reach-us"
                component={ReachToUsScreen}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/call-us"
                component={callToUsScreen}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/change-password"
                component={changePassword}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/update_pan_details"
                component={UpdatePanDetails}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/apply-for-deposit"
                component={applyForDeposit}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/email-us"
                component={EmailToUsScreen}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/tds"
                component={TdsScreen}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/form-15G"
                component={Form_15G}
              />
              <ProtectedDepositPortalRoute
                exact
                path="/deposits/form-15H"
                component={Form_15H}
              />
              <Route
                exact
                path="/lending/login"
                component={CustomerLendingLogin}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/pay-dues-online"
                component={PayDuesOnlineScreen}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/payment-payu"
                component={PaymentPayU}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/payment-hdfc"
                component={PaymentHDFC}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/area-office"
                component={LendingReachUsScreen}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/home"
                component={LendingDashboard}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/pdc"
                component={PDC}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/nach-mandate"
                component={NACHCancellation}
              />

              <ProtectedLendingPortalRoute
                exact
                path="/lending/loan-information"
                component={LoanInformation}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/cro"
                component={CRO}
              />

              <ProtectedLendingPortalRoute
                exact
                path="/lending/apply-loan"
                component={ApplyLoanLending}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/repayment-schedule"
                component={RepaymentSchedule}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/pmay-clss-update"
                component={PmayClssUpdate}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/agent-reach-us"
                component={AgentReachUs}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/write-to-us"
                component={LendingWriteToUs}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/repayment-ledger"
                component={RepaymentLedger}
              />

              <ProtectedLendingPortalRoute
                exact
                path="/lending/repay-certificate"
                component={RepayCertificate}
              />

              <ProtectedLendingPortalRoute
                exact
                path="/lending/merge-loans"
                component={MergeLoans}
              />
              <ProtectedLendingPortalRoute
                exact
                path="/lending/loan-disbursement"
                component={LoanDisbursement}
              /> */}

              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Suspense>
        </Layout>
      </CaptchaProvider>
    </BrowserRouter>
  );
}

export default App;
