import {
  SET_DEPOSIT_BASIC_DETAILS,
  DEPOSIT_BASIC_DETAILS,
  RESET_DEPOSIT_BASIC_DETAILS,
  LENDING_BASIC_DETAILS,
  SET_LENDING_BASIC_DETAILS,
  RESET_LENDING_BASIC_DETAILS,
  API_DATA,
  SET_API_DATA,
  SELECTED_LANGUAGE,
  SET_SELECTED_LANGUAGE,
} from '../../constants/context_constants';
import {strings} from '../../constants/strings';
import contextProviderStore from './Context';

const initialState = {
  [SELECTED_LANGUAGE]:
    localStorage.getItem(SELECTED_LANGUAGE) || strings.ENGLISH_LANGUAGE,
  [API_DATA]: {
    sidebarData: {},
    stickyMenuData: {},
    commonButtonStrings: {},
    emiCalculatorJSON: {},
  },
  [DEPOSIT_BASIC_DETAILS]: {
    //deposit_customer_portal
    emailId: '',
    mobileNumber: '',
    refId: '',
    currentActiveStage: 0,
    loginToken: '',
    fireBaseToken: '',
    panCard: '',
    limitExceededCount: 0,
  },
  [LENDING_BASIC_DETAILS]: {
    //lending_customer_portal
    emailId: '',
    mobileNumber: '',
    loanNumbers: [],
    currentActiveStage: 'lendingCustomerInfo',
    loginToken: '',
    fireBaseToken: '',
    panCard: '',
    personalInfo: '',
    limitExceededCount: 0,
  },
};

const depositReducer = (state, {type, payload}) => {
  switch (type) {
    case SET_API_DATA:
      return {
        ...state,
        [API_DATA]: {...state[API_DATA], ...payload},
      };

    case SET_SELECTED_LANGUAGE:
      return {
        ...state,
        [SELECTED_LANGUAGE]: payload,
      };

    case SET_DEPOSIT_BASIC_DETAILS:
      return {
        ...state,
        [DEPOSIT_BASIC_DETAILS]: {...state[DEPOSIT_BASIC_DETAILS], ...payload},
      };

    case SET_LENDING_BASIC_DETAILS:
      return {
        ...state,
        [LENDING_BASIC_DETAILS]: {...state[LENDING_BASIC_DETAILS], ...payload},
      };

    case RESET_DEPOSIT_BASIC_DETAILS:
      return {
        ...state,
        [DEPOSIT_BASIC_DETAILS]: {...initialState[DEPOSIT_BASIC_DETAILS]},
      };

    case RESET_LENDING_BASIC_DETAILS:
      return {
        ...state,
        [LENDING_BASIC_DETAILS]: {
          ...initialState[LENDING_BASIC_DETAILS],
        },
      };

    default:
      return state;
  }
};

const {
  StoreProvider,
  useStore,
  useActions,
  useDepositPortalBasicDetails,
  useLendingPortalBasicDetails,
  useSingletonApiData,
  useSelectedMultilingualLanguage,
} = contextProviderStore(depositReducer, initialState);

export {
  StoreProvider,
  useStore,
  useActions,
  useDepositPortalBasicDetails,
  useLendingPortalBasicDetails,
  useSingletonApiData,
  useSelectedMultilingualLanguage,
};
