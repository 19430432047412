import React from 'react';
import {Dialog} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default function YesNoModal({
  onNoClick,
  onYesClick,
  title,
  description,
  isModalOpen = false,
}) {
  const handleYesClick = () => {
    onYesClick && onYesClick();
  };

  const handleNoClick = (event, reason) => {
    onNoClick && onNoClick(reason);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleNoClick}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleYesClick} autoFocus>
          Yes
        </Button>
        <Button onClick={handleNoClick} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
