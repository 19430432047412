export const cacheConfig = {
  HOME_RENOVATION_CACHE_KEY: 'homeRenovation',
  PLOT_LOAN_CACHE_KEY: 'plotLoan',
  BALANCE_TRANSFER_CACHE_KEY: 'balanceTransfer',
  EXTENSION_LOAN_CACHE_KEY: 'extensionLoan',
  BUILDER_DEVELOPER_CACHE_KEY: 'builderDeveloper',
  CORPORATE_CACHE_KEY: 'corporate',
  LOAN_AGAINST_RENTAL_CACHE_KEY: 'loanAgainstRental',
  CORPORATE_OFFICE_CACHE_KEY: 'corporateOffice',
  LOAN_AGAINST_SECURITIES_KEY: 'loanAgainstSecurties',
  FOOTER_CACHE_KEY: 'footerData',
  GRIHA_SUVIDHA_CACHE_KEY: 'grihaSuvidha',
  FAQ_CACHE_KEY: 'faq',
  CORPORATE_DEPOSIT_CACHE_KEY: 'corpDeposit',
  PUBLIC_DEPOSIT_CACHE_KEY: 'publicDeposit',
  GOVERNANCE_CERTIFICATE_CACHE_KEY: 'corpGovernanceCertificate',
  PROFILE_OF_DIRECTORS_CACHE_KEY: 'profileOfDirectors',
  BOARD_OF_DIRECTORS_CACHE_KEY: 'boardOfDirectors',
  HOME_PAGE_CACHE_KEY: 'homePage',
  HOME_CONSTRUCTION_CACHE_KEY: 'homeConstruction',
  HOUSING_LOAN_CACHE_KEY: 'housingLoan',
  LOAN_AGAINST_PROPERTY_CACHE_KEY: 'loanAgainstProperty',
  LOAN_AGAINST_PROPERTY_COMPANIES_CACHE_KEY: 'loanAgainstPropertyCompanies',
  LOAN_AGAINST_PROFESSIONAL_CACHE_KEY: 'loanAgainstProfessional',
  NRI_LOAN_CACHE_KEY: 'nriLoan',
  PMAY_CACHE_KEY: 'pmay',
  TOPUP_LOAN_CACHE_KEY: 'topupLoan',
  ADVANTAGE_PLUS_CACHE_KEY: 'advantagePlus',
  ABOUT_US_CACHE_KEY: 'aboutUs',
  WRITE_TO_US_CACHE_KEY: 'writeToUs',
  CALL_US_CACHE_KEY: 'callUs',
  HOME_LOAN_ENQUIRY_CACHE_KEY: 'homeLoanEnquiry',
  INTEREST_RATE_CACHE_KEY: 'interestRate',
  GRIEVANCE_READDRESSAL_MECHANISM_CACHE_KEY: 'grievanceReaddressalMechanism',
  LISTING_EQUITY_SHARES_CACHE_KEY: 'listingEquityShares',
  DIVIDEND_CACHE_KEY: 'dividend',

  FINANCIAL_RESULT_CACHE_KEY: 'financialResult',
  POSTAL_BALLOT_CACHE_KEY: 'postalBallot',
  PRESS_RELEASE_CACHE_KEY: 'pressRelease',
  CONSOLIDATED_RELATED_CACHE_KEY: 'consolidatedRelated',
  INVESTOR_UPDATE_CACHE_KEY: 'investorUpdate',
  SHAREHOLDERS_RELATIONS_CACHE_KEY: 'shareHoldersRelations',
  SHAREHOLDERS_PATTERN_CACHE_KEY: 'shareHoldersPattern',
  CONCALL_TRANSCRIPT_CACHE_KEY: 'concallTranscript',
  CONCALL_AUDIO_CACHE_KEY: 'concallAudio',
  SUBSIDIARY_FINANCIALS_CACHE_KEY: 'subsidiaryFinancial',
  EXTRA_ORDINARY_GENERAL_MEETING_CACHE_KEY: 'extraOrdinaryGeneralMeeting',
  TRANSFERS_OF_SHARES_CACHE_KEY: 'transfersOfShares',
  AMENDMENT_TO_TRANSFERS_OF_SHARES_CACHE_KEY: 'amendmentToTransfersOfShares',
  INVESTORS_EDUCATION_PROTECTION_FUND_CACHE_KEY:
    'investorsEducationProtectionFund',
  SHAREHOLDERS_DESK_CACHE_KEY: 'shareholdersDesk',
  ANNUAL_REPORT_CACHE_KEY: 'annualReport',

  COMPREHENSIVE_NOTICE_BOARD_CACHE_KEY: 'comprehensiveNoticeBoard',
  DOWNLOADS_CACHE_KEY: 'downloads',
  TENDERS_CACHE_KEY: 'tenders',
  TENDERS_DETAILS_CACHE_KEY: 'tendersDetails',
  OFFICE_LOCATOR_DIGITAL_CHANNEL_CACHE_KEY: 'officeLocator',
  OFFICE_LOCATOR_DIGITAL_CHANNEL_CONTACT_CACHE_KEY: 'officeLocatorConatct',
  HOME_LOAN_PENSIONERS_CACHE_KEY: 'homeLoanPensioners',
  USAGE_POLICY_CACHE_KEY: 'usagePolicy',
  PRIVACY_POLICY_CACHE_KEY: 'privacyPolicy',
  REGULATORY_DISCLOSURE: 'regulatoryDisclosure',
  PUBLIC_DISCLOSURE: 'publicDisclosure',
  REGISTER_OFFICE_CACHE_KEY: 'registerOfficeKey',
  REGIONAL_OFFICE_CACHE_KEY: 'regionalOfficeKey',
  OVERSEAS_OFFICE_CACHE_KEY: 'overseasOfficeKey',
  NON_CONVERTIBLE_DEBENTURE_CACHE_KEY: 'nonConvertibleDebenture',
  DIRECT_SELLING_AGENCY_CACHE_KEY: 'directSellingAgency',
  BUSINESS_DOWNLOAD_CACHE_KEY: 'businessDownload',
  AREA_OFFICE_CACHE_KEY: 'areaOfficeCacheKey',
  PROPERTY_AUCTION_CACHE_KEY: 'propertyAuctionKey',
  CONTACT_US_CACHE_KEY: 'contactUsKey',
  CORONA_GUIDANCE_AND_SUPPORT_CACHE_KEY: 'coronaGuidanceAndSupport',
  POLICIES_AND_CODES: 'policyAndCodes',
  COMMERCIAL_PAPER_CACHE_KEY: 'commercialPaper',
  BOARD_MEETING_CACHE_KEY: 'commercialPaper',
  FIXED_DEPOSITS_CACHE_KEY: 'fixedDeposits',
  COMMITEES_CACHE_KEY: 'commiteesKey',
  POLICIES_AND_CODES_CACHE_KEY: 'policyAndCodes',
  APPLICATION_FORM_CACHE_KEY: 'applicationForm',
  ARCHIVE_NEWS_CACHE_KEY: 'archiveNews',
  DEBENTURES_CACHE_KEY: 'debentures',
  ANNUAL_GENERAL_MEETING_CACHE_KEY: 'Annual General Meeting',
  LOAN_ELIGIBILITY_CACHE_KEY: 'loanEligibility',
  REGULATION_UNDER_SEBI_CACHE_KEY: 'regulationUnderSebi',
  INTIMATION_UNDER_SEBI_CACHE_KEY: 'intimationUnderSebi',

  ENQUIRY_TYPE_CACHE_KEY: 'enquiryType',
  COMPLAINT_TYPE_CACHE_KEY: 'complaintType',
  TICKER_BAR_CACHE_KEY: 'scrollerdetails',
  REQUEST_TYPE_CACHE_KEY: 'requestType',
  RESIGNATION_LETTER_CACHE_KEY: 'resignationLetter',
  APPOINTMENT_LETTER_CACHE_KEY: 'appointmentLetter',
  MEETING_ATTENDANCE_CACHE_KEY: 'meetingAttendance',
  LHPLR_CACHE_KEY: 'lhplr',
  CUSTOMER_EDUCATION_CACHE_KEY: 'customerEducation',
  COROPORATE_ANNOUNCEMENT_CACHE_KEY: 'COROPORATE_ANNOUNCEMENT_CACHE_KEY',
  ANNUAL_RETURN_COMPANIES_ACT_CACHE_KEY:
    'ANNUAL_RETURN_COMPANIES_ACT_CACHE_KEY',
  SECRETARY_COMPLAINCE_CACHE_KEY: 'SECRETARY_COMPLAINCE_CACHE_KEY',
  SUBMIT_RESUME_CACHE_KEY: 'submitResume',
  JOB_OPPORTUNITIES: 'jobOpportunities',
  DASHBOARD_CACHE_KEY: 'dashboard',
  JOB_OPPORTUNITIES_CACHE_KEY: 'jobOpportunitiesData',

  COROPORATE_ANNOUNCEMENT_CACHE_KEY: 'COROPORATE_ANNOUNCEMENT_CACHE_KEY',
  ANNUAL_RETURN_COMPANIES_ACT_CACHE_KEY:
    'ANNUAL_RETURN_COMPANIES_ACT_CACHE_KEY',
  SECRETARY_COMPLAINCE_CACHE_KEY: 'SECRETARY_COMPLAINCE_CACHE_KEY',
  STCKY_MENU_CACHE_KEY: 'stickyMenu',
  HEADER_CONSTANT_CACHE_KEY: 'header',
  SIDEBAR_CONSTANT_CACHE_KEY: 'sideBar',
  CUSTOMER_LOGIN_CACHE_KEY: 'customerLogin',
  SUBMIT_RESUME_ACKNOWLEDGE_CACHE_KEY: 'submitResumeAcknowledge',
  SUBMIT_RESUME_GENERATE_OTP_CACHE_KEY: 'submitResumeGenerateOtp',
  LANGAUAGE_DROPDOWN_CACHE_KEY: 'langauageDropdownCacheKey',
  LENDING_COMMON_LINKS_CACHE_KEY: 'lendingLinks',
  DEPOSIT_COMMON_LINKS_CACHE_KEY: 'depositLinks',
};
