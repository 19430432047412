import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`

    html {
     
      box-sizing: border-box;
      line-height: 1.15;

      // @media ${(props) => props.theme.mediaQueries.largest} {
      //     font-size: 60%;
      // }

    }

    * {
      outline: none;
      box-sizing: inherit;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    // ::selection {
    //     background: var(--primary-lighter); /* WebKit/Blink Browsers */
    //   }

      ::-moz-selection {
        color: #fff !important;
        background: var(--primary) !important;
      }
      
      ::selection {
        color: #fff !important;
        background: var(--primary) !important;
      }

    body {
      font-family: 'Roboto','Montserrat', 'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", '!default';
      margin: 0;
      width: 100%;
      height: 100%;
      overflow: visible;
      --primary: ${(props) => props.theme.colors.main};
      --primary-yellow: ${(props) => props.theme.colors.yellow};
      --primary-text: ${(props) => props.theme.colors.textTheme.text};
      --text-highlight: ${(props) =>
        props.theme.colors.textTheme.textHighlight};
      --white: #fff;
        --horizontal-spacing: 4rem;
        --header-text-size: 46px;
        --header-line-height: 58px;
        --lending-portal-padding:1rem;

        @media ${(props) => props.theme.mediaQueries.largest} {
          --horizontal-spacing: 6rem;
    }

        @media ${(props) => props.theme.mediaQueries.minidesktop} {
          --horizontal-spacing: 2rem;
    }

        @media ${(props) => props.theme.mediaQueries.medium} {
          --horizontal-spacing: 3rem;
          --header-text-size: 36px;
          --header-line-height: 46px;
    }

        @media ${(props) => props.theme.mediaQueries.small} {
            --horizontal-spacing: 1.5rem;
            --header-text-size: 36px;
            --header-line-height: 46px;
      }
    }

    form,
    input,
    textarea,
    button,
    select,
    a {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    .labelPreview {
      color: rgba(0,0,0,1) !important;
    }
`;
