import React from 'react';
import styled from 'styled-components';
import Slider from '../../components/slider_component';
import BackgroundCircle from '../../components/UI/background_circle';
import MainSectionHeader from '../../components/UI/main_section_header';
import Loader from '../../components/UI/loader';
import ErrorText from '../../components/UI/error_text';
import PDFCardComponent from '../../components/UI/pdf_card_component';
import MetaTag from '../../components/seo_component/metaTag';
import useGetCustomerEducation from '../../apiRequests/customer_education_request';

const MainWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
`;
const SubWrapper = styled.section`
  width: 100%;
  padding: 2rem 0rem 2rem 0rem;
  z-index: 1;
`;

const CardWrapper = styled.div`
  display: flex;
  margin: 0 var(--horizontal-spacing);
`;

export default function CustomerEducation() {
  const {isLoading, error, data} = useGetCustomerEducation();

  if (isLoading) return <Loader loading={true} />;

  if (error) return <ErrorText />;

  const bread_crumb_data = data.dataCrumbData;

  return (
    <div>
      <Slider
        data={data.banner}
        isHome={false}
        bread_crumb_data={bread_crumb_data}
      />
      <MetaTag
        title={'Customer Education'}
        description={
          'Finance Company, Home Loan Providers In India,Dubai,Kuwait, Home Loans – With you for your dream home.Find easy Housing Loan for your needs from LIC HFL. Get lowest interest rates and fulfill the dream of owning your dream home.'
        }
      />
      <CardWrapper>
        <MainWrapper>
          <SubWrapper>
            <MainSectionHeader data={data.title} />
            <PDFCardComponent data={data.pdfContents} pdfRowCount={4} />
          </SubWrapper>
          <BackgroundCircle />
        </MainWrapper>
      </CardWrapper>
    </div>
  );
}
