module.exports = {
  //deposit_customer_portal
  DEPOSIT_BASIC_DETAILS: 'deposit_basic_details',
  SET_DEPOSIT_BASIC_DETAILS: 'SET_DEPOSIT_BASIC_DETAILS',
  RESET_DEPOSIT_BASIC_DETAILS: 'RESET_DEPOSIT_BASIC_DETAILS',
  DEPOSIT_AUTHENTICATED_TOKEN: 'DEPOSIT_AUTHENTICATED_TOKEN',

  //lending_customer_portal
  LENDING_BASIC_DETAILS: 'lending_basic_details',
  SET_LENDING_BASIC_DETAILS: 'SET_LENDING_BASIC_DETAILS',
  RESET_LENDING_BASIC_DETAILS: 'RESET_LENDING_BASIC_DETAILS',
  LENDING_AUTHENTICATED_TOKEN: 'LENDING_AUTHENTICATED_TOKEN',

  API_DATA: 'API_DATA',
  SET_API_DATA: 'SET_API_DATA',
  SELECTED_LANGUAGE: 'SELECTED_LANGUAGE',
  SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
};
