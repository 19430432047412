import React from 'react';
import styled from 'styled-components';
import useConfigurationRequest from '../../../apiRequests/getconfigmessagecms_api';
import backgroundImage from '../../../assets/images/DashBoardBackground.png';
import {useLocation} from 'react-router-dom';
import './bannerComponent.css';

const MainWrapper = styled.section`
  background: linear-gradient(
      to right,
      rgba(0, 82, 156, 0.76) 0%,
      rgba(223, 235, 245, 0) 102.58%
    ),
    url(${backgroundImage});
  border-radius: 10px;
  min-height: 192px;
  padding: 25px;
`;

const InterestWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function BannerComponent() {
  const [data, setData] = React.useState([]);
  const {mutateAsync} = useConfigurationRequest();
  const location = useLocation();
  const {pathname} = location;
  const isDeposit = pathname.startsWith('/deposits/');

  async function postRequest() {
    const postResponse = await mutateAsync({
      module: isDeposit ? 'WEB-DEPOSIT' : 'WEB-LENDING',
      configurationPage: isDeposit
        ? 'WEB_DEPOSIT_BANNER'
        : 'WEB_LENDING_BANNER',
      channel: 'WEB',
    });

    setData(postResponse?.data);
  }
  React.useEffect(() => {
    postRequest();
  }, []);

  return (
    <MainWrapper>
      {isDeposit ? (
        <div className="deposit-title">{data?.section1}</div>
      ) : (
        <>
          <div className="lending-title">{data?.section1}</div>
          <InterestWrapper>
            <>
              <div className="lending-interestRate">{data?.section2}</div>
              <div className="lending-content">{data?.section3}</div>
            </>
          </InterestWrapper>
          <div className="lending-footer">{data?.section4}</div>
        </>
      )}
    </MainWrapper>
  );
}
