import React from 'react';
import SideMenuSection from './UI/side_menu_section';
import styled from 'styled-components';
import closemessage from '../assets/images/close.png';
import {useSingletonApiData} from '../../src/store/GlobalContext/ContextStore';

const StyledImage = styled.img`
  width: 15px;
  height: 15px;
  align-self: center;
  cursor: pointer;
`;
const ChatMessageText = styled.div`
  background: #ededed;
  width: 250px;
  align-self: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 2.5%;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: auto;
  }
`;

const ChatStyledText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #5b5b5b;
  margin: 0% 5%;
`;

const RightArrowView = styled.div`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 14px solid #ededed;
  align-self: center;
`;

export default function RightStickyMenu({isOnlyChatbotDisplay = false}) {
  const CHAT_MESSAGE = 'message_value';
  const [showChatMessage, setShowChatMessage] = React.useState(
    localStorage.getItem(CHAT_MESSAGE),
  );

  const onClick = () => {
    localStorage.setItem(CHAT_MESSAGE, 'false');
    setShowChatMessage(localStorage.getItem(CHAT_MESSAGE));
  };

  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;

  const {commonButtonStrings} = useSingletonApiData();

  if (isOnlyChatbotDisplay) {
    return (
      <div
        style={
          isMobile
            ? {
                position: 'fixed',
                bottom: 20,
                right: 100,
                zIndex: 3,
              }
            : {
                position: 'fixed',
                zIndex: 3,
                bottom: 60,
                right: 100,
              }
        }>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          {showChatMessage === 'true' || showChatMessage === true ? (
            <>
              <ChatMessageText>
                <StyledImage src={closemessage} alt="close" onClick={onClick} />
                <ChatStyledText>
                  {commonButtonStrings?.chatbotText}
                </ChatStyledText>
              </ChatMessageText>
              <RightArrowView />
            </>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div>
      {!isMobile && (
        <div
          style={{
            position: 'fixed',
            right: 0,
            zIndex: 5,
            flexDirection: 'column',
          }}>
          <SideMenuSection />
        </div>
      )}
      <div
        style={
          isMobile
            ? {
                position: 'fixed',
                bottom: 20,
                right: 100,
                zIndex: 3,
              }
            : {
                position: 'fixed',
                zIndex: 3,
                bottom: '60px',
                right: '100px',
              }
        }>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          {showChatMessage === 'true' || showChatMessage === true ? (
            <>
              <ChatMessageText>
                <StyledImage src={closemessage} alt="close" onClick={onClick} />
                <ChatStyledText>
                  {commonButtonStrings.chatbotText}
                </ChatStyledText>
              </ChatMessageText>
              <RightArrowView />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
