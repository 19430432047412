import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Activities} from '../../../constants/json_constants';
const MainContainer = styled.div``;

const Divider = styled.div`
  border: 2px solid #eaeaea;
  margin: 15px 10px;
`;

const SubRowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  @media ${(props) => props.theme.mediaQueries.small} {
    padding: 0;
    margin: 0;
  }
`;

const ButtonHeadingText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #00529c;
  margin-left: 15px;
  margin-top: 10px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const TitleWrapper = styled(Link)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
  margin: 12px 10px;
`;

export default function ActivitiesCard({handleSelect}) {
  const [active, setActive] = React.useState('Loan Information');
  const activeStyle = {color: '#00529C', textDecoration: 'none'};
  const inactiveStyle = {textDecoration: 'none'};

  const handleClick = (title) => {
    handleSelect(title);
    console.log('Title is', title);
    setActive(title);
  };

  return (
    <MainContainer>
      <SubRowWrapper>
        <ButtonHeadingText>Activities</ButtonHeadingText>
      </SubRowWrapper>
      <Divider />
      <StyledColumn>
        {Activities.map((el) => {
          return (
            <TitleWrapper
              style={active === el.subTitle ? activeStyle : inactiveStyle}
              to={el.path}
              onClick={() => handleClick(el.subTitle)}>
              {el.subTitle}
            </TitleWrapper>
          );
        })}
      </StyledColumn>
    </MainContainer>
  );
}
