import React from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";
import { formatNumInCommas } from "../../utils/app_utils";

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .horizontal-slider {
    width: 100%;
    max-width: 1000px;
    height: 38px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .example-thumb {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    text-align: center;
    background-color: var(--primary);
    color: white;
    cursor: pointer;
    box-sizing: border-box;
  }

  .example-track {
    position: relative;
    background: linear-gradient(90deg, #00529c 0%, rgba(0, 82, 156, 0.64) 100%);
  }

  .example-track.example-track-1 {
    background: #eeeeee;
  }

  .horizontal-slider .example-track {
    top: 15px;
    height: 10px;
    cursor: pointer;
  }

  .horizontal-slider .example-thumb {
    top: 6px;
    width: 28px;
    height: 28px;
    line-height: 38px;
    border-radius: 3px;
  }

  .horizontal-slider .example-thumb::before {
    content: "";
    display: block;
    position: absolute;
    left: 8px;
    width: 2px;
    height: 15px;
    background-color: white;
    z-index: -1;
    overflow: hidden;
  }

  .horizontal-slider .example-thumb::after {
    content: "";
    display: block;
    position: absolute;
    left: 18px;
    width: 2px;
    height: 15px;
    background-color: white;
    z-index: -1;
    overflow: hidden;
  }
`;

const StyledLabel = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
  text-align: left;
`;

const StyledRupeeValue = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #000000;
`;

const StyledValue = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #000000;
`;

const StyledSliderRange = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #5b5b5b;
`;

function HomeLoanSlider({ label, initialValue, minValue, maxValue }) {
  const [value, setValue] = React.useState(initialValue);
  const [textFieldVal, setTextFieldVal] = React.useState(
    formatNumInCommas(initialValue)
  );

  function onSliderChange(sliderVal) {
    setValue(sliderVal);

    setTextFieldVal(formatNumInCommas(sliderVal));
  }

  return (
    <SliderWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StyledLabel>{label}</StyledLabel>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <StyledRupeeValue>₹</StyledRupeeValue>
          <StyledValue>{textFieldVal}</StyledValue>
        </div>
      </div>
      <ReactSlider
        value={value}
        min={minValue}
        max={maxValue}
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        onAfterChange={(val, index) => {
          //api request here
        }}
        onChange={(val) => {
          onSliderChange(val);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <StyledSliderRange>1L</StyledSliderRange>
        <StyledSliderRange>10Cr</StyledSliderRange>
      </div>
    </SliderWrapper>
  );
}

export default HomeLoanSlider;
