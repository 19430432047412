import React from 'react';
import {
  createContext,
  useContextSelector,
  useContext,
} from 'use-context-selector';
import {
  API_DATA,
  DEPOSIT_BASIC_DETAILS,
  LENDING_BASIC_DETAILS,
  SELECTED_LANGUAGE,
} from '../../constants/context_constants';
import {
  resetDepositBasicDetailsUtil,
  setDepositBasicDetailsUtil,
  setLendingBasicDetailsUtil,
  resetLendingBasicDetailsUtil,
  setSingletonApiDataUtil,
  setSelectedMultilingualLanguageUtil,
} from './DispatchUtils';

export default function contextProviderStore(reducer, initialState) {
  const dispatchContext = createContext();
  const storeContext = createContext();

  const StoreProvider = ({children}) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);

    const updatedStoreVal = React.useRef(store);

    // const getStore = () => updatedStoreVal.current;

    React.useEffect(() => {
      updatedStoreVal.current = store;
    }, [store]);

    const contextActions = React.useMemo(
      () => ({
        setDepositBasicDetails: (depositDetails) =>
          setDepositBasicDetailsUtil({
            storeDetails: updatedStoreVal.current,
            depositDetails,
            dispatch,
          }),
        setLendingBasicDetails: (lendingDetails) =>
          setLendingBasicDetailsUtil({
            storeDetails: updatedStoreVal.current,
            lendingDetails,
            dispatch,
          }),
        setSingletonApiData: (apiData) =>
          setSingletonApiDataUtil({
            apiData,
            dispatch,
          }),
        setSelectedMultilingualLanguage: (lang) =>
          setSelectedMultilingualLanguageUtil({
            lang,
            dispatch,
          }),
        resetDepositBasicDetails: () =>
          resetDepositBasicDetailsUtil({
            storeDetails: updatedStoreVal.current,
            dispatch,
          }),
        resetLendingBasicDetails: () =>
          resetLendingBasicDetailsUtil({
            storeDetails: updatedStoreVal.current,
            dispatch,
          }),
      }),
      [],
    );

    const storeValue = React.useMemo(() => store, [store]); //separate out actions & store for performance

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dispatchValue = React.useMemo(() => contextActions, []);

    return (
      <dispatchContext.Provider value={dispatchValue}>
        <storeContext.Provider value={storeValue}>
          {children}
        </storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  function GetContextVal(contextVal) {
    const context = useContext(contextVal);
    if (context === undefined) {
      throw new Error('Context must be used within a Provider');
    }
    return context;
  }

  function useActions() {
    return GetContextVal(dispatchContext);
  }

  function useStore() {
    return GetContextVal(storeContext);
  }

  //returns specific part of context & avoids re-rendering

  function useDepositPortalBasicDetails() {
    return useContextSelector(storeContext, (v) => v[DEPOSIT_BASIC_DETAILS]);
  }

  function useLendingPortalBasicDetails() {
    return useContextSelector(storeContext, (v) => v[LENDING_BASIC_DETAILS]);
  }

  function useSingletonApiData() {
    return useContextSelector(storeContext, (v) => v[API_DATA]);
  }

  function useSelectedMultilingualLanguage() {
    return useContextSelector(storeContext, (v) => v[SELECTED_LANGUAGE]);
  }

  return {
    StoreProvider,
    useStore,
    useActions,
    useDepositPortalBasicDetails,
    useLendingPortalBasicDetails,
    useSingletonApiData,
    useSelectedMultilingualLanguage,
  };
}
