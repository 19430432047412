const theme = {
  colors: {
    main: '#00529c',
    yellow: '#ffcc35',
    textTheme: {
      text: '#00529c',
      textHighlight: '#333',
      background: '#fff',
    },
    footerBackground: '#E5E5E5',
    footerTextColor: '#5B5B5B',
  },
  mediaQueries: {
    mobile: 'only screen and (max-width: 575px)',
    small: 'only screen and (max-width: 767px)',
    medium: 'only screen and (max-width: 949px)',
    minidesktop: 'only screen and (max-width: 1149px)',
    large: 'only screen and (min-width: 1150px)',
    largest: 'only screen and (min-width: 1550px)',
  },
};

export default theme;
