import styled from "styled-components";

const BlueHeaderTextStyle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  font-family: "Roboto";
  color: #00529c;
  margin: 1.5rem 0;

  ${(props) => props.style && { ...props.style }}
`;

export const BlueHeader = ({ text, style }) => (
  <BlueHeaderTextStyle style={style}>{text}</BlueHeaderTextStyle>
);
