import React, {Suspense, lazy, useState} from 'react';
import Slider from '../../components/slider_component';
import useHomePageApiRequest from '../../apiRequests/home_page_api_request';
import Loader from '../../components/UI/loader';
import {strings} from '../../constants/strings';
import PopupHome from '../../components/UI/popup_home';
import GetArchiveNews from '../../apiRequests/get_archive_news_api_request';
import Tickerbar from './Tickerbar';
import useTickerBar from '../../apiRequests/ticker_bar_api';
import {useSelectedMultilingualLanguage} from '../../store/GlobalContext/ContextStore';
import {useEffect} from 'react';
// const Tickerbar = lazy(() => import('./Tickerbar'));
// const PopupHome = lazy(() => import('../../components/UI/popup_home'));
// const Slider = lazy(() => import('../../components/slider_component'));
const MetaTag = lazy(() => import('../../components/seo_component/metaTag'));
const HomeMessage = lazy(() => import('./home_message_section/home_message'));
const HomeFlipCards = lazy(() => import('./home_flip_cards/home_flip_cards'));
const VideoSection = lazy(() => import('./video_section/video_section'));
const EmiCalculator = lazy(() => import('./emi_calculator/emi_calculator'));
const DownloadAppSection = lazy(() =>
  import('./download_app_section/download_app_section'),
);
const OtherCardsSection = lazy(() =>
  import('./other_cards_section/other_cards_section'),
);

function Home() {
  const selectedContextLanguage = useSelectedMultilingualLanguage();
  useEffect(() => {
    saveTickerData();
  }, []);
  const [apidata, Setapidata] = useState('');
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const {mutateAsync: Tickerdata} = useTickerBar();
  // const Tickerdata = null
  const {data, error, isLoading} = useHomePageApiRequest();
  const {
    data: archiveNewsData,
    error: newsError,
    isLoading: newsLoading,
  } = GetArchiveNews();

  if (isLoading) return <Loader loading={true} />;
  if (error) return <div>{strings.SOMETHING_WENT_WRONG}</div>;

  async function saveTickerData() {
    let dataResponse = await Tickerdata({
      language: selectedContextLanguage === 'eng' ? 'ENGLISH' : 'HINDI',
    });
    Setapidata(dataResponse);
  }

  function onClosePopup() {
    // localStorage.setItem(POPUP_KEY, true);
  }

  return (
    <div className="home_page_main_div">
      {
        <>
          <Tickerbar data={apidata} />
          <PopupHome onClosePopup={onClosePopup} data={data.popup} />
          <Slider data={data.carousel} isHome={true} isHomePage={true} />{' '}
        </>
      }
      <Suspense fallback={<Loader loading={true} />}>
        {data ? (
          <div>
            <MetaTag
              title={
                'Home Loan | Apply Housing Loan at Lowest Interest Rate | Home Loan EMI Calculator '
              }
              description={
                'Finance Company, Home Loan Providers In India, Dubai, Home Loans – With you for your dream home. Find easy Housing Loan for your needs from LIC HFL.'
              }
              keywords={
                'Housing Finance, Housing Finance Ltd, Housing Finance Companies'
              }
            />
            <HomeMessage data={data.message} isMobile={isMobile} />
            <HomeFlipCards data={data.cards} isMobile={isMobile} />
            {data.vedios?.length > 0 && <VideoSection data={data.vedios} />}
            <EmiCalculator
              isMobile={isMobile}
              containerStyle={{
                background:
                  'linear-gradient(180deg, #f9f9f9 0%, rgba(238, 238, 238, 0) 100%)',
              }}
            />
            <DownloadAppSection
              data={data.downloadMessages}
              isMobile={isMobile}
            />
            <OtherCardsSection
              data={data.otherCards}
              archiveNewsData={archiveNewsData}
              isMobile={isMobile}
            />
          </div>
        ) : null}
      </Suspense>
    </div>
  );
}

export default Home;
