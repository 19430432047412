import React, {useEffect, useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import styled from 'styled-components';
import plusIcon from '../../assets/images/plusIcon.png';
import minusIcon from '../../assets/images/minusIcon.png';
import parse from 'html-react-parser';
import SectionHeaderText from './section_header_text';
import MUIBasicTable from './mUIbasic_table';

const NumericContainer = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffcb08;
  align-self: center;
  padding-left: 2%;
`;

const HeaderContainer = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 58px;
  display: flex;
  align-items: center;
  font-feature-settings: 'cpsp' on;
  padding-left: 3%;
  color: #000000;

  @media ${props => props.theme.mediaQueries.medium} {
    line-height: 20px;
  }
`;

const TextSection = styled.div`
  margin: 0% 2%;
  width: 100%;
  text-align: start;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
`;

const ImageContainer = styled.div``;

const NoteSection = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
`;
const UnderLine = styled.div`
  border: 1px solid #eaeaea;
  margin: 0 !important;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 2px !important;
`;

const StyledAccordianContainer = styled.div`
  margin: 2% 0%;
`;

const ResponsiveDiv = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;

  @media ${props => props.theme.mediaQueries.medium} {
    overflow-x: auto;
  }
`;

const ResponsiveTable = styled.div`
  @media ${props => props.theme.mediaQueries.medium} {
    overflow-x: scroll;
  }
`;

function MUIAccordian({data, id}) {
  const [accordianState, setAccordianState] = useState(() => {});
  const [renderFlag, setRerenderFlag] = useState(false);

  let dataArr = Object.values(data);
  let sortedData = dataArr.sort((a, b) => {
    if (a.position !== undefined) {
      return a.position - b.position;
    }
  });

  useEffect(() => {
    if (sortedData !== undefined && !renderFlag) {
      setAccordianState(sortedData);
      setRerenderFlag(true);
    }
  }, [sortedData]);

  const handleChange = (keyName, val) => {
    var newArr = {...accordianState};

    Object.keys(newArr).map(el => {
      if (el !== 'header') {
        newArr[el].message[0].expanded = false;
      }
    });
    newArr[keyName].message[0].expanded = val;
    setAccordianState(newArr);
  };

  const accordianHeader = (keyName, index, value) => {
    return <div></div>;
  };
  const accordianRender = (keyName, index, value) => {
    index++;
    const indNumber = ('0' + index).slice(-2);
    return (
      <StyledAccordianContainer
        id="accordian_click"
        className={'accordianOuterDiv'}
        onClick={() =>
          handleChange(keyName, !accordianState[keyName].message[0].expanded)
        }>
        <Accordion expanded={value?.message[0]?.expanded}>
          <AccordionSummary
            expandIcon={
              value?.message[0]?.expanded === false ? (
                <ImageContainer>
                  <img src={plusIcon} alt={'NOT FOUND'}></img>
                </ImageContainer>
              ) : (
                <ImageContainer>
                  <img src={minusIcon} alt={'NOT FOUND'}></img>
                </ImageContainer>
              )
            }>
            <NumericContainer style={{paddingRight: '20px'}}>
              {indNumber}
            </NumericContainer>
            <HeaderContainer>{value?.message[0].title}</HeaderContainer>
          </AccordionSummary>
          <UnderLine />
          <AccordionDetails style={{}}>
            <ResponsiveDiv>
              {value.message[0].description ? (
                <div>
                  <TextSection>
                    {parse(value.message[0].description)}
                  </TextSection>
                </div>
              ) : null}
              <ResponsiveTable>
                <MUIBasicTable table={value}></MUIBasicTable>
              </ResponsiveTable>
              {value.note ? (
                <div>
                  <TextSection>{value?.note[0]?.description}</TextSection>
                </div>
              ) : null}
            </ResponsiveDiv>
          </AccordionDetails>
        </Accordion>
      </StyledAccordianContainer>
    );
  };
  return (
    <div>
      {accordianState !== undefined ? (
        <div>
          {accordianState.header ? (
            <>
              {accordianState.header.map(el => {
                return <SectionHeaderText id={id} title={el.title} />;
              })}
            </>
          ) : null}
          {Object.entries(accordianState).map(([keyName, value], index) => {
            if (value.position === undefined) {
              return accordianHeader(keyName, index, value);
            } else {
              return accordianRender(keyName, index, value);
            }
          })}
          {accordianState.header ? (
            <>
              {accordianState.header.map(el => {
                return <NoteSection>{el.note}</NoteSection>;
              })}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default MUIAccordian;
