import React from 'react';
import commonButtonStringRequest from '../apiRequests/common_button_string_request';
import emiCalculatorJsonRequest from '../apiRequests/emi_calculator_json_request';
import {strings} from '../constants/strings';
import {
  useActions,
  useSelectedMultilingualLanguage,
} from '../store/GlobalContext/ContextStore';
import {delay} from './app_utils';
import UseSidebarConstant from '../apiRequests/sidebar_const_api';

export default function SingletonDataAccess() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const apiStateCache = React.useRef({});
  const {setSingletonApiData} = useActions();
  const selectedLanguage = useSelectedMultilingualLanguage();

  // const {sidebarData} = useSingletonApiData(); //use in respective components

  const commonButtonStrings = async () => {
    const {data} = await commonButtonStringRequest();
    console.log(data, 'button string');
    return {key: 'commonButtonStrings', value: data.commonButtonStrings};
  };

  const emiCalculatorJSON = async () => {
    const {data} = await emiCalculatorJsonRequest();
    return {key: 'emiCalculatorJSON', value: data};
  };

  const headerApiCall = async () => {
    if (apiStateCache.current.headerData)
      return apiStateCache.current.headerData;
    await delay(2000); //TODO: Make header JSON API cal here
    return {
      key: 'headerData',
      value: [
        {
          subMenuText: 'Home Loans',
          displayTwoCircles: true,
        },
      ],
    };
  };

  const sideBarApiCall = async () => {
    if (apiStateCache.current.sidebarData)
      return apiStateCache.current.sidebarData;
    // await delay(300);
    try {
      const {isLoading, error, data} = await UseSidebarConstant();
      return {key: 'sidebarData', value: data};
    } catch (e) {}
  };

  React.useEffect(() => {
    async function resolveAllApiCall() {
      try {
        const resolvedData = await Promise.all([
          headerApiCall(),
          sideBarApiCall(),
          commonButtonStrings(),
          emiCalculatorJSON(),
        ]);
        const arrToObj = resolvedData.reduce((acc, {key, value}) => {
          acc[key] = value;
          return acc;
        }, {});
        setSingletonApiData(arrToObj);
        apiStateCache.current = resolvedData;
        setIsLoading(false);
        setError(null);
        console.log(apiStateCache.current, 'STATECACHE!', arrToObj);
      } catch (err) {
        setIsLoading(false);
        setError(err);
      }
    }

    resolveAllApiCall();
  }, []);

  React.useEffect(() => {
    // handleSelectedLanguageChange();
  }, [selectedLanguage]);

  async function handleSelectedLanguageChange() {
    console.log('changing seelected lang', selectedLanguage);
    setIsLoading(true);
    localStorage.setItem(strings.SELECTED_LANGUAGE, selectedLanguage);
    setIsLoading(false);
  }

  return {
    isApiLoading: isLoading,
    apiError: error,
    apiStateCache: apiStateCache.current,
  };
}
