import React from 'react';

export default function EnterKeyPress({onEnterClick}) {
  React.useEffect(() => {
    const listener = (event) => {
      if (
        event.code === 'Enter' ||
        event.code === 'NumpadEnter' ||
        event.keyCode === 13
      ) {
        event.preventDefault();
        onEnterClick();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onEnterClick]);

  return <></>;
}
