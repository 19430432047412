import {
  SET_DEPOSIT_BASIC_DETAILS,
  RESET_DEPOSIT_BASIC_DETAILS,
  SET_LENDING_BASIC_DETAILS,
  RESET_LENDING_BASIC_DETAILS,
  SET_API_DATA,
  SET_SELECTED_LANGUAGE,
} from '../../constants/context_constants';

export const setDepositBasicDetailsUtil = ({
  storeDetails,
  depositDetails,
  dispatch,
}) => {
  dispatch({type: SET_DEPOSIT_BASIC_DETAILS, payload: depositDetails});
};

export const resetDepositBasicDetailsUtil = ({storeDetails, dispatch}) => {
  dispatch({type: RESET_DEPOSIT_BASIC_DETAILS, payload: {}});
};

export const setLendingBasicDetailsUtil = ({
  storeDetails,
  lendingDetails,
  dispatch,
}) => {
  dispatch({type: SET_LENDING_BASIC_DETAILS, payload: lendingDetails});
};

export const setSingletonApiDataUtil = ({apiData = {}, dispatch}) => {
  dispatch({type: SET_API_DATA, payload: apiData});
};

export const setSelectedMultilingualLanguageUtil = ({lang = '', dispatch}) => {
  dispatch({type: SET_SELECTED_LANGUAGE, payload: lang});
};

export const resetLendingBasicDetailsUtil = ({storeDetails, dispatch}) => {
  dispatch({type: RESET_LENDING_BASIC_DETAILS, payload: {}});
};
