import {useQuery} from 'react-query';
import {config} from '../constants/config';
import {cacheConfig} from '../constants/query_constants';
import AxiosInstance from '../utils/axios_instance';

export default function useStickyMenuRight() {
  return useQuery(cacheConfig.STCKY_MENU_CACHE_KEY, async () => {
    const {data} = await AxiosInstance({
      method: 'get',
      url:
        config.BASE_API_URL +
        'site_const_util/sticky_menu/sticky_menu.json' +
        config.CMS_PARAMETERS,
    });
    return data;
  });
}