import React from 'react';
import ErrorText from '../../../components/UI/error_text';
import Loader from '../../../components/UI/loader';
import styled from 'styled-components';
import MainSectionHeader from '../../../components/UI/main_section_header';
import MetaTag from '../../../components/seo_component/metaTag';
import QueryLink from '../../../components/UI/query_link';
import Slider from '../../../components/slider_component';
import useGetPemiCovid from '../../../apiRequests/pemi_covid_api';

const MainPadding = styled.div`
  padding: 3rem;
  @media ${(props) => props.theme.mediaQueries.medium} {
    padding: 0rem;
  }
`;

const PemiCovid = () => {
  const {isLoading, error, data} = useGetPemiCovid();

  if (isLoading) return <Loader loading={true} />;
  if (error) return <ErrorText />;
  const bread_crumb_data = [{title: 'EMI Relief'}];
  return (
    <div id="EMI_Relief_div">
      <Slider
        data={data.banner}
        isHome={false}
        bread_crumb_data={bread_crumb_data}
      />

      <MetaTag
        title={'EMI Relief'}
        description={
          'Finance Company, Home Loan Providers In India,Dubai,Kuwait, Home Loans – With you for your dream home.Find easy Housing Loan for your needs from LIC HFL. Get lowest interest rates and fulfill the dream of owning your dream home.'
        }
      />
      <MainPadding>
        <MainSectionHeader data={data.title} />
      </MainPadding>
      <QueryLink data={data.queryServices} />
    </div>
  );
};

export default PemiCovid;
