import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";

import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/app_utils";

const CollapsibleMenu = ({
  name,
  Icon,
  items,
  containerStyle,
  textStyle,
  displayTwoCircles,
  navigateTo,
  onLinkClicked,
  customDividerStyle,
  isExternalLink,
}) => {
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);

  // console.log(items, "INCOMING ITEMS", name);

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <div>
      <ListItem
        style={{
          padding: "1.1rem",
          letterSpacing: 0.5,
          color: "var(--primary)",
          ...containerStyle,
        }}
        button
        className={classes.menuItem}
        onClick={handleClick}
      >
        {/* Display an icon if any */}
        {!!Icon && (
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon />
          </ListItemIcon>
        )}
        {navigateTo ? (
          isExternalLink ? (
            <a
              target="_blank"
              onClick={() => {
                scrollToTop();
                onLinkClicked();
              }}
              style={{ textDecoration: "none" }}
              href={navigateTo}
            >
              <div
                style={{ padding: 0, color: "var(--primary)", ...textStyle }}
              >
                {name}
              </div>
            </a>
          ) : (
            <Link
              onClick={() => {
                scrollToTop();
                onLinkClicked();
              }}
              style={{ textDecoration: "none" }}
              to={navigateTo}
            >
              <div
                style={{ padding: 0, color: "var(--primary)", ...textStyle }}
              >
                {name}
              </div>
            </Link>
          )
        ) : (
          <div
            style={{
              padding: 0,
              flex: 1,
              color: "var(--primary)",
              ...textStyle,
            }}
          >
            {name}
          </div>
        )}
        {/* Display the expand menu if the item has children */}
        {isExpandable && !open && <IconExpandMore />}
        {isExpandable && open && <IconExpandLess />}
      </ListItem>
      {customDividerStyle ? (
        <div style={{ paddingLeft: 35, background: "#F5F5F5" }}>
          <div
            style={{
              height: 1,
              background: "#E5E5E5",
            }}
          />
        </div>
      ) : (
        <Divider />
      )}
    </div>
  );

  //if first item has headingText, then next level nested children else map through list of first el
  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items[0].headingText
          ? items?.map(({ headingText, list, ...item }, index) => (
              <CollapsibleMenu
                {...item}
                key={headingText}
                name={headingText}
                containerStyle={{
                  background: "#F9F9F9",
                  padding: "10px 1.1rem",
                }}
                textStyle={{
                  color: "black",
                  letterSpacing: 0.5,
                  fontWeight: "500",
                }}
                onLinkClicked={onLinkClicked}
                items={[...list]}
                displayTwoCircles={displayTwoCircles}
                isExternalLink={isExternalLink}
              />
            ))
          : displayTwoCircles
          ? items.map(({ text, link, id }) => (
              <CollapsibleMenu
                key={text}
                name={text}
                items={[]}
                containerStyle={{
                  background: "#F5F5F5",
                  padding: "10px 10px",
                  paddingLeft: "35px",
                }}
                textStyle={{ color: "black", fontWeight: "normal" }}
                customDividerStyle={true}
                onLinkClicked={() => onLinkClicked(id)}
                navigateTo={link}
                isExternalLink={isExternalLink}
              />
            ))
          : items.map(({ list }) =>
              list.map(({ text, link, id }) => (
                <CollapsibleMenu
                  containerStyle={{
                    background: "#F5F5F5",
                    padding: "10px 10px",
                    paddingLeft: "35px",
                  }}
                  customDividerStyle={true}
                  textStyle={{ color: "black", fontWeight: "normal" }}
                  key={text}
                  name={text}
                  items={[]}
                  onLinkClicked={() => onLinkClicked(id)}
                  navigateTo={link}
                  isExternalLink={isExternalLink}
                />
              ))
            )}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {},
    menuItemIcon: {
      color: "#97c05c",
    },
  })
);

export default CollapsibleMenu;
