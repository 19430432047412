import {useMutation} from 'react-query';
import {config} from '../constants/config';
import {useLendingPortalBasicDetails} from '../store/GlobalContext/ContextStore';
import AxiosInstance from '../utils/axios_instance';

export default function useLendingGetProfile() {
  const {loginToken} = useLendingPortalBasicDetails();
  return useMutation(async (payload) => {
    const {data} = await AxiosInstance({
      method: 'post',
      url: `${config.OAUTH_API_URL}getlendingprofilephoto`,
      data: payload,
      headers: {
        Authorization: `Bearer ${loginToken}`,
        apikey: `${config.APIGEE_API_KEY}`,
      },
    });
    return data;
  });
}
