import React from 'react';
import {Link} from 'react-router-dom';
import {Select, MenuItem, FormControl, makeStyles} from '@material-ui/core';
import styled from 'styled-components';

const DropDownWrapper = styled.div`
  width: 250px;
  height: 40px;
  padding: 2px 20px;
  align-content: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: #ffffff;
  box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
    height: 40px;
    padding: 0px;
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '90%',
    marginLeft: '5%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 500,
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}));

export default function LoanDropDown({
  dropdownValues,
  dropdownAction,
  selectedValue,
  disabled,
  isLoan = false,
  placeHolder,
}) {
  const classes = useStyles();

  return (
    <DropDownWrapper>
      <FormControl className={classes.formControl}>
        <Select
          displayEmpty
          disableUnderline
          renderValue={selectedValue !== '' ? undefined : () => placeHolder}
          classes={{root: classes.selectRoot}}
          labelId="dropdown-simple-select-label"
          id="dropdown-simple-select"
          value={selectedValue}
          disabled={disabled}
          onChange={dropdownAction}
          MenuProps={{
            classes: {paper: classes.menuPaper},
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}>
          {dropdownValues?.map((option, index) => (
            <MenuItem
              component={isLoan ? '' : Link}
              to={option.path}
              key={index}
              value={option?.subTitle || option}>
              {option?.subTitle || option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </DropDownWrapper>
  );
}
