import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import '../../styles/common.css';
import '../../../src/styles/breadcrumb.css';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styled from 'styled-components';
import {useSingletonApiData} from '../../store/GlobalContext/ContextStore';

const InActiveBreadCrumb = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: #ffffff;
`;

const ActiveBreadCrumb = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
`;

const breadcrumbStyle = {
  margin: 'auto',
  display: 'table',
  zIndex: 1,
  position: 'absolute',
  color: '#ffffff',
  marginLeft: '5%',
  marginTop: '2%',
};

const linkStyle = {
  textDecoration: 'none',
  color: '#fff',
};

export default function BreadCrumb({data, isTopNavigation}) {
  const {commonButtonStrings} = useSingletonApiData();

  return (
    <>
      {data ? (
        <div style={breadcrumbStyle} className="breadcrumbRoot">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <NavigateNextIcon
                fontSize="small"
                style={{fontSize: 30, color: '#fff'}}
              />
            }>
            <InActiveBreadCrumb href="/" style={linkStyle} className="homeLink">
              {commonButtonStrings?.homeBreadCrumbs}
            </InActiveBreadCrumb>
            {data.length !== 1 ? (
              <InActiveBreadCrumb
                href={data[0].link}
                style={linkStyle}
                className="headerLink">
                {data[0].title}
              </InActiveBreadCrumb>
            ) : null}

            <Typography color="textPrimary">
              <ActiveBreadCrumb className="pageLink">
                {data.length === 1 ? data[0].title : data[1].title}
              </ActiveBreadCrumb>
            </Typography>
          </Breadcrumbs>
        </div>
      ) : null}
    </>
  );
}
