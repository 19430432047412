import React, {useEffect} from 'react';
import styled from 'styled-components';
import {config} from '../../constants/config';
import {Dialog} from '@material-ui/core';
import PopupButton from './popup_button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import parse from 'html-react-parser';

const styles = (theme) => ({
  root: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  closeButton: {
    position: 'relative',
    color: '#024182',
    zIndex: 4,
  },
});

const HeadingText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 46.161px;
  line-height: 54px;
  color: #024182;
  margin: 0;
  @media ${(props) => props.theme.mediaQueries.small} {
    font-size: 35px;
    line-height: 40px;
  }
`;
const MobileHeadingText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 46.161px;
  line-height: 54px;
  color: #024182;
  margin: 0;
  @media ${(props) => props.theme.mediaQueries.small} {
    font-size: 35px;
    line-height: 40px;
  }
`;

const NumberHeadingText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 54px;
  color: #024182;
  @media ${(props) => props.theme.mediaQueries.small} {
    font-size: 20px;
    line-height: 25px;
  }
`;

const SubHeadingText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 19.7833px;
  line-height: 16px;
  letter-spacing: 0.329721px;
  color: #024182;
`;

const ConditionText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 7.91331px;
  letter-spacing: 0.329721px;
  color: #000000;
  opacity: 0.5;
`;

const UnderLine = styled.hr`
  background-color: #015098;
  border-color: #015098;
  width: 100%;
  height: 2px;
  margin: 20px 0px 20px 0px;
  @media ${(props) => props.theme.mediaQueries.small} {
    margin: 5px 0px 15px 0px;
  }
`;

const StyledImage = styled.img``;

const OuterDivContent = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`;

const ImageDiv = styled.div`
  display: flex;
  width: 35%;
  z-index: 1;
`;

const ContentDiv = styled.div`
  display: flex;
  background: linear-gradient(
    270deg,
    #ffffff 56.54%,
    rgba(255, 255, 255, 0) 93.64%
  );
  width: 65%;
  padding: 7% 2% 3% 20%;
  z-index: 3;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
    padding: 7% 2% 3% 7%;
    background: transparent;
  }
`;

const ContentInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ColumnMainDiv = styled.div`
  width: 100%;
  display: flex;
`;

const ColumnDiv = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const HurryupImage = styled.img`
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 150%;
  }
`;

const HurryupImageDiv = styled.div`
  // position: absolute;
  width: 30%;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  align-content: flex-end;
  display: flex;
  // height: 70%;
`;
const ImageBackground = styled.div`
  width: 100%;
  @media ${(props) => props.theme.mediaQueries.small} {
    background-repeat: no-repeat;
    background-size: 100%;
  }
`;

export default function PopupHome({onClosePopup, data}) {
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClosePopup();
    setOpen(false);
  };

  useEffect(() => {
    handleClickOpen();
  }, []);

  const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {onClose ? (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
    },
  }))(MuiDialogContent);

  const mobileRender = (val, imageURL, image2Url) => {
    return (
      <>
        <ImageBackground style={{backgroundImage: `url(${imageURL})`}}>
          <ContentDiv>
            <ContentInnerDiv>
              <MobileHeadingText>{parse(`${val.title1}`)}</MobileHeadingText>
              <HeadingText>{parse(`${val.title2}`)}</HeadingText>
              <UnderLine />
              <ColumnMainDiv>
                <ColumnDiv>
                  <NumberHeadingText>
                    {parse(`${val.subtitle1}`)}{' '}
                    <SubHeadingText>{parse(`${val.subtitle2}`)}</SubHeadingText>
                  </NumberHeadingText>
                  <ConditionText>{'*' + parse(`${val.caption}`)}</ConditionText>
                  <PopupButton
                    buttonTitle={val.buttontitle}
                    buttonLink={val.buttonlink}
                  />
                </ColumnDiv>
                <HurryupImageDiv>
                  <HurryupImage src={image2Url} alt="hurry up" />
                </HurryupImageDiv>
              </ColumnMainDiv>
            </ContentInnerDiv>
          </ContentDiv>
        </ImageBackground>
      </>
    );
  };

  return (
    <div>
      {data ? (
        <Dialog
          open={open}
          className={'home_page_first_pop_up'}
          onClose={handleClose}
          fullWidth
          maxWidth={'md'}>
          <DialogContent>
            <DialogTitle onClose={handleClose} />
            <OuterDivContent>
              {Object.entries(data).map(([key, val]) => {
                const image1Url = `${config.BASE_BACKEND_URL}${val.image1}${config.CMS_PARAMETERS}`;
                const image2Url = `${config.BASE_BACKEND_URL}${val.image2}${config.CMS_PARAMETERS}`;
                const image3Url = `${config.BASE_BACKEND_URL}${val.image3}${config.CMS_PARAMETERS}`;
                return (
                  <>
                    {!isMobile ? (
                      <>
                        <ImageDiv>
                          <StyledImage src={image1Url} alt="back url" />
                        </ImageDiv>
                        <ContentDiv>
                          <ContentInnerDiv>
                            <HeadingText>{parse(`${val.title1}`)}</HeadingText>
                            <HeadingText>{parse(`${val.title2}`)}</HeadingText>
                            <UnderLine />
                            <ColumnMainDiv>
                              <ColumnDiv>
                                <NumberHeadingText>
                                  {parse(`${val.subtitle1}`)}{' '}
                                  <SubHeadingText>
                                    {parse(`${val.subtitle2}`)}
                                  </SubHeadingText>
                                </NumberHeadingText>
                                <ConditionText>
                                  {'*' + parse(`${val.caption}`)}
                                </ConditionText>
                                <PopupButton
                                  buttonTitle={val.buttontitle}
                                  buttonLink={val.buttonlink}
                                />
                              </ColumnDiv>
                              <HurryupImageDiv>
                                <HurryupImage src={image2Url} alt="hurry up" />
                              </HurryupImageDiv>
                            </ColumnMainDiv>
                          </ContentInnerDiv>
                        </ContentDiv>
                      </>
                    ) : (
                      mobileRender(val, image3Url, image2Url)
                    )}
                  </>
                );
              })}
            </OuterDivContent>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}
