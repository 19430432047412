import React from 'react';
import ErrorText from '../../../components/UI/error_text';
import Loader from '../../../components/UI/loader';
import styled from 'styled-components';
import MainSectionHeader from '../../../components/UI/main_section_header';
import parse from 'html-react-parser';
import MetaTag from '../../../components/seo_component/metaTag';
import useGetPublicHolidays from '../../../apiRequests/public_holidays_api';
import QueryLink from '../../../components/UI/query_link';
import Slider from '../../../components/slider_component';
import {config} from '../../../constants/config';

const Wrapper = styled.section`
  background: linear-gradient(180deg, #f9f9f9 0%, rgba(238, 238, 238, 0) 100%);
  padding: 2rem;
`;
const MainPadding = styled.div`
  padding: 3rem;
  @media ${(props) => props.theme.mediaQueries.medium} {
    padding: 0rem;
  }
`;
const SplitDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  @media ${(props) => props.theme.mediaQueries.medium} {
    padding: 0rem;
  }
`;
const BoldText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  padding: 1rem 0rem;
  letter-spacing: 0.5px;
  font-feature-settings: 'cpsp' on;

  color: #000000;
`;

const DescDiv = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-allign: center;
  /* or 158% */

  letter-spacing: 0.5px;

  color: #5b5b5b;
`;
const PublicHolidays = () => {
  const {isLoading, error, data} = useGetPublicHolidays();

  if (isLoading) return <Loader loading={true} />;
  if (error) return <ErrorText />;

  const bread_crumb_data = [{title: 'Public Holidays'}];
  const imageUrl = `${config.BASE_BACKEND_URL}${data.message[0].image}${config.CMS_PARAMETERS}`;
  return (
    <div id="public_holidays_div">
      <Slider
        data={data.banner}
        isHome={false}
        bread_crumb_data={bread_crumb_data}
      />

      <MetaTag
        title={'Public Holidays'}
        description={
          'Finance Company, Home Loan Providers In India,Dubai,Kuwait, Home Loans – With you for your dream home.Find easy Housing Loan for your needs from LIC HFL. Get lowest interest rates and fulfill the dream of owning your dream home.'
        }
      />
      <MainPadding>
        <MainSectionHeader data={data.title} />
        <Wrapper>
          <img src={imageUrl} alt="backend url" />
          <BoldText>{data.message[0].title}</BoldText>
          <BoldText>{data.message[0].subtitle}</BoldText>
          <DescDiv>{parse(data.message[0].description)}</DescDiv>
          <SplitDiv>
            <BoldText>{data.message[0].date}</BoldText>
            <BoldText>{data.message[0].name}</BoldText>
          </SplitDiv>
        </Wrapper>
      </MainPadding>
      <QueryLink data={data.queryServices} />
    </div>
  );
};

export default PublicHolidays;
