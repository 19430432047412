import AxiosInstance from '../utils/axios_instance';
import {config} from '../constants/config';

export default function emiCalculatorJsonRequest() {
  return AxiosInstance({
    method: 'get',
    url:
      config.BASE_API_URL +
      'site_const_util/emi_calculator/emi_calc.json' +
      config.CMS_PARAMETERS,
  });
}
