import AxiosInstance from '../utils/axios_instance';
import {useQuery} from 'react-query';
import {config} from '../constants/config';
import {cacheConfig} from '../constants/query_constants';

export default function useGetLendingPortalLinks() {
  return useQuery(cacheConfig.LENDING_COMMON_LINKS_CACHE_KEY, async () => {
    const {data} = await AxiosInstance({
      method: 'get',
      url: `${config.BASE_API_URL}site_const_util/lending_portal/lending_portal.json${config.CMS_PARAMETERS}`,
    });
    return data;
  });
}
