import { useQuery } from "react-query";
import { config } from "../constants/config";
import { cacheConfig } from "../constants/query_constants";
import AxiosInstance from "../utils/axios_instance";

export default function GetLhplr() {
  return useQuery(cacheConfig.LHPLR_CACHE_KEY, async () => {
    const { data } = await AxiosInstance({
      method: "get",
      url: config.BASE_API_URL + "footer_pg/lhplr.json" + config.CMS_PARAMETERS,
    });
    return data;
  });
}
