import React, { useState, useEffect } from "react";
import packageJson from "../package.json";

global.appVersion = packageJson.version;
const semverGreaterThan = (versionA, versionB, isRolledBack) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    if (isRolledBack) {
      return a < b;
    } else {
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
  }
  return false;
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestVersion, setIsLatestVersion] = useState(false);

    useEffect(() => {
      fetch(`/version.json`)
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = global.appVersion;
          const isRolledBack = meta.rollback;

          const shouldForceRefresh = semverGreaterThan(
            latestVersion,
            currentVersion,
            isRolledBack
          );

          if (shouldForceRefresh) {
            setIsLatestVersion(false);
            refreshCacheAndReload();
          } else {
            setIsLatestVersion(true);
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (window.caches) {
        // Service worker cache should be cleared with caches.delete()
        window.caches.keys().then(function (names) {
          for (const name of names) window.caches.delete(name);
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    return (
      <React.Fragment>
        {isLatestVersion ? <Component {...props} /> : null}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
