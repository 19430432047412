import styled from "styled-components";

export const MainTopLinksWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;

  ul {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }

  ul li {
    margin: 0 1rem;
    display: flex;
    width: max-content;
  }

  .main-top-links .active > a:not(.menu-title),
  .main-top-links li:hover > a:not(.menu-title) {
    color: black;
  }

  .menu-active-underline > li.active > a,
  .menu-active-underline > li:hover > a {
    color: inherit;
  }

  .main-top-links > li > a {
    position: relative;
    padding: 1.5rem 0;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.2;
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .main-top-links li a {
    padding: 0.7rem 0 0.7rem 1rem;
    padding-bottom: 1.2rem;
  }

  .main-top-links a {
    display: inline-block;
  }

  .header-right a {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
    cursor: pointer;
  }

  .menu-active-underline > li.active > a:before {
    transform-origin: left center;
    transform: scale(1, 1);
  }

  .menu-active-underline > li > a:before {
    bottom: 8px;
  }

  .menu-active-underline > li > a:before {
    content: "";
    display: block;
    color: var(--primary);
    position: absolute;
    bottom: 9px;
    left: 8px;
    width: 100%;
    border-bottom: 2px solid;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s;
    cursor: pointer;
  }
`;
