import styled from 'styled-components';

export const DesktopDropdownMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  padding: 5px 0;
  background: #fff;

  .dropdown-menu {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    justify-content: space-between;
  }

  nav {
    flex: 1;
    max-width: 1100px;
  }

  /* on hover megahover-menu is visible */
  .dropdown-menu li:hover .megahover-menu,
  .dropdown-menu li:hover > ul {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    top: 44px;
    z-index: 1201;
  }

  .dropdown-menu li .megahover-menu,
  .dropdown-menu li > ul {
    position: absolute;
    top: -9999px;
    // left: 100%;
    margin: 0;
    -webkit-box-shadow: 0 2px 35px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 35px rgb(0 0 0 / 10%);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.2s ease-out;
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  .megahover-menu {
    display: flex;
    flex: 1;
    width: max-content;
    background: #fff;
    overflow: hidden;
  }

  .dropdown-menu-list-item > a:after {
    margin-left: 0.5rem;
  }

  .dropdown-menu-item-text-linktag {
    font-style: normal;
    font-weight: 500;
    font-size: 0.98rem;
    color: var(--primary);
    font-family: 'Roboto';
    position: relative;

    @media ${(props) => props.theme.mediaQueries.minidesktop} {
      font-size: 0.88rem;
    }
  }

  .dropdown-menu-list-item {
    display: flex;
    width: max-content;
    // margin: 0 1rem;
    width: max-content;

    position: relative;
    line-height: 1.5;
  }

  .megahover-menu .megahover-menu-row {
    flex: 1 1;
    padding: 0 2rem;
  }

  .megahover-menu-row {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: blue;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    cursor: pointer;
  }

  .dropdown-menu-item-text > * {
    text-decoration: none;
    color: var(--primary);
    cursor: pointer;
  }

  .dropdown-menu-item-text:hover,
  .dropdown-menu-item-text:hover > * {
    color: black;
    cursor: pointer;
  }

  .megahover-menu-li:hover {
    color: var(--primary);
    transform: translate(20px, 0px);
  }

  .megahover-menu-li {
    text-decoration: none;
    color: black;
    font-size: 0.85rem;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    cursor: pointer;
    margin: 0.3rem 0;
    font-weight: 500;
    font-family: 'Roboto';
    transform: translate(0px, 0px);
    transition: transform 0.3s linear;
  }

  .megahover-menu-ul {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;

    background: #fff;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    color: #666;
    list-style-type: none;
    font-family: 'Roboto';
  }

  .curve {
    position: absolute;
    top: -50px;
    left: -50px;

    text-align: center;
  }
  .curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    background-color: var(--primary-yellow);
    // -webkit-transform: translate(-4%, 40%);
    // -ms-transform: translate(-4%, 40%);
    // transform: translate(-30px, -70px);
    z-index: -1;
    overflow: hidden;
  }

  .end-curve {
    position: absolute;
    bottom: -50px;
    right: -50px;

    text-align: center;
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 135px;
    height: 135px;
    background-color: var(--primary-yellow);
    z-index: 1000;
    overflow: hidden;
  }

  .small-end-curve {
    position: absolute;
    bottom: -50px;
    right: -50px;

    text-align: center;
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 85px;
    height: 85px;
    background-color: var(--primary-yellow);
    z-index: 1000;
    overflow: hidden;
  }

  .vertical-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #2a2a72;
    background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 84%);
  }
`;
