import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import '../../styles/common.css';
import styled from 'styled-components';
import {config} from '../../constants/config';
import ReactCardFlip from 'react-card-flip';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #f9f9f9 0%, rgba(238, 238, 238, 0) 100%);

  @media ${(props) => props.theme.mediaQueries.medium} {
    flex-direction: column;
    padding-bottom: 5%;
  }
`;

const MainQueryDiv = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 5% 5%;
  @media ${(props) => props.theme.mediaQueries.medium} {
    flex-direction: column;
  }
`;

const ChildQueryDiv = styled.div`
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  text-align: center;
  display: flex;
  height: 145px;
  flex-direction: column;
`;

const LinkBox = styled.a`
  // padding: 2%;
  // width: 18%;
  background: #ffffff;
  box-shadow: 4px 9px 52px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  height: 145px;
  width: 215px;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  text-align: center;
  color: #000000;
  text-decoration: none;
  transition: all 0.1s linear;
  @media ${(props) => props.theme.mediaQueries.medium} {
    width: 100%;
  }
`;

const StyledText = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #000000;
`;

const StyledTextHover = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
`;

function QueryLink(props) {
  const [flippedIndex, setFlippedIndex] = React.useState(-1);

  const querySection = () => {
    const sortData = props.data.sort((a, b) =>
      a.imagePosition > b.imagePosition ? 1 : -1,
    );

    return Object.values(sortData).map((val, key) => {
      const imageUrl = `${config.BASE_BACKEND_URL}${val.image}${config.CMS_PARAMETERS}`;
      return (
        <Wrapper>
          <LinkBox
            href={val.link}
            target={val.type === 'weblink' ? `_blank` : ``}>
            <ReactCardFlip
              isFlipped={flippedIndex === key}
              flipDirection="horizontal"
              style={{transition: ' all 0.1s linear', height: '100%'}}>
              <div
                onMouseOver={() => setFlippedIndex(key)}
                style={{
                  borderRadius: 10,
                  height: '100%',
                }}>
                <ChildQueryDiv>
                  <img src={imageUrl} alt="backend url" />
                  <StyledText>{val.title}</StyledText>
                </ChildQueryDiv>
              </div>
              <div
                onMouseLeave={() => setFlippedIndex(-1)}
                style={{
                  background:
                    'linear-gradient(301.97deg, #024182 1.86%, rgba(2, 65, 130, 0.64) 98.56%)',
                  cursor: 'pointer',
                  borderRadius: 10,
                  height: '100%',
                }}>
                <ChildQueryDiv>
                  <img src={imageUrl} alt="backend url" />
                  <StyledTextHover>{val.title}</StyledTextHover>
                </ChildQueryDiv>
              </div>
            </ReactCardFlip>
          </LinkBox>
        </Wrapper>
      );
    });
  };
  return <MainQueryDiv>{querySection()}</MainQueryDiv>;
}

export default QueryLink;
