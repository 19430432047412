import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { config } from '../../constants/config';
import Modal from 'react-modal';
import Close from '@material-ui/icons/Close';
import parse from 'html-react-parser';
import '../../styles/pdf_card.css';

const MainWrapper = styled.section`
  margin: 50px 0px;
`;

const MainContainer = styled.button`
  justify-content: center;
  display: flex;
  outline: none;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  box-shadow: 4px 9px 52px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  height: 100%;
  width: 100%;
  border: 0;
  padding: 10px 20px;
  min-height: 110px;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  flex: 1;
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
const TextContentWrapper = styled.div`
  flex: 2;
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
const TextWrapper = styled.div`
  align-self: center;
  flex-basis: 300px;
  text-align: start;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: start;
  letter-spacing: 0.5px;
  color: #000000;
  flex: display;
  flex-direction: column;
  padding: 0px 10px;
`;

const TextHeading = styled.div`
  align-self: center;
  flex-basis: 300px;
  text-align: start;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: start;
  letter-spacing: 0.5px;
  color: #000000;
  flex: display;
  flex-direction: column;
  padding: 0px 10px;
`;

const StyledImage = styled.img``;

const IframeWrapper = styled.div`
  position: relative;
  margin: 5px;
  #download {
    display: none;
    opactity: 0;
    visibility: hidden;
  }
`;

const closeStyle = {
  color: 'var(--primary)',
  alignSelf: 'end',
  marginTop: '-.5rem',
  marginRight: '1rem',
  position: 'absolute',
  top: '-12px',
  right: '-37px',
  cursor: 'pointer',
  zIndex: 999999,
  width: '22px',
  height: '22px',
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function PDFCardComponent({ data, pdfRowCount, wrapperStyle }) {
  let sortedData = data.sort((a, b) => {
    return a.messagePosition - b.messagePosition;
  });

  const [isDisplayed, setIsDisplayed] = React.useState(false);
  React.useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
    }, 1000);
  }, []);
  const { height, width } = getWindowDimensions();
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;

  const [modalLink, setModalLink] = React.useState(false);
  const customStyles = {
    content: {
      position: 'absolute',
      left: isMobile ? width * 0.05 : width * 0.25,
      right: isMobile ? width * 0.05 : width * 0.25,
      top: isMobile ? height * 0.15 : height * 0.07,
      bottom: isMobile ? width * 0.15 : height * 0.07,
      transform: 'translate(-50%,-50%) !important',
    },
    overlay: { zIndex: 10000 },
  };

  function closeModal() {
    setModalLink(false);
    setIsDisplayed(false);
  }

  function openAudio(link, be_link, isDownload) {
    if (be_link != null) {
      if (
        (be_link.includes('.pdf') && !isDownload) ||
        (be_link.includes('.PDF') && !isDownload)
      ) {
        setModalLink(link);
      } else if (
        be_link.includes('.pdf') ||
        be_link.includes('.PDF') ||
        be_link.includes('.mp3')
      ) {
        window.open(link, '_blank');
      } else if (be_link.includes('.php')) {
        window.open(be_link, '_blank');
      } else {
        window.open(be_link, '_self');
      }
    }
  }

  const ModalDisplay = () => {
    return (
      <Modal
        isOpen={modalLink}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Pdf Modal">
        <IframeWrapper>
          <Close onClick={closeModal} style={closeStyle} />
          <embed
            frameborder="0"
            controls
            controlsList="nodownload"
            src={modalLink}
            title={'pdf'}
            style={{
              width: isMobile ? width / 1.3 : '100%',
              height: isMobile ? height / 1.4 : height / 1.3,
              border: 'none',
              overflow: 'hidden',
            }}
          />
          {isDisplayed && (
            <>
              <div
                style={{
                  position: 'absolute',
                  height: 54,
                  width: 150,
                  color: 'red',
                  background: '#323639',
                  zIndex: 200,
                  top: 0,
                  right: 50,
                }}
              />
            </>
          )}
        </IframeWrapper>
      </Modal>
    );
  };
  return (
    <MainWrapper style={wrapperStyle}>
      {ModalDisplay()}
      <Grid container spacing={4}>
        {sortedData.map((el, index) => (
          <Grid
            item
            xs={12}
            sm={pdfRowCount ? 12 / pdfRowCount : 4}
            md={pdfRowCount ? 12 / pdfRowCount : 4}
            key={index}>
            <MainContainer
              onMouseDown={(e) => {
                if (e.button === 1) {
                  openAudio(
                    `${config.BASE_BACKEND_URL}${el.link}${config.CMS_PARAMETERS}${config.EMBEDDED}`,
                    el.link,
                    el.isDownloadable,
                  );
                }
              }}
              id={(el?.title || el?.description || el?.particulars || el.name || el.link
              )
                .toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, '_')}
              onClick={() =>
                openAudio(
                  `${config.BASE_BACKEND_URL}${el.link || el.url}${config.CMS_PARAMETERS
                  }${config.EMBEDDED}`,
                  el.link || el.url,
                  el.isDownloadable,
                )
              }>
              <ImageWrapper>
                <StyledImage
                  alt="back url"
                  src={`${config.BASE_BACKEND_URL}${el.image || el.pdfIcon}${config.CMS_PARAMETERS
                    }${config.EMBEDDED}`}
                />
              </ImageWrapper>
              <TextContentWrapper>
                {el.displayDate ? (
                  <TextHeading>{parse(`${el.displayDate}`)}</TextHeading>
                ) : null}
                <TextWrapper>
                  {parse(`${el.title || el.description || el.name}`)}
                </TextWrapper>
              </TextContentWrapper>
            </MainContainer>
          </Grid>
        ))}
      </Grid>
    </MainWrapper>
  );
}
