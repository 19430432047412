import {useQuery} from 'react-query';
import {config} from '../constants/config';
import {cacheConfig} from '../constants/query_constants';
import AxiosInstance from '../utils/axios_instance';

export default function UseSidebarConstant() {
  return AxiosInstance({
    method: 'get',
    url:
      config.BASE_API_URL +
      'site_const_util/sidebar/sidebar.json' +
      config.CMS_PARAMETERS,
  });
}
