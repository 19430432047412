import { useQuery } from "react-query";
import { config } from "../constants/config";
import { cacheConfig } from "../constants/query_constants";
import AxiosInstance from "../utils/axios_instance";

export default function useHomePageApiRequest() {
  return useQuery(cacheConfig.HOME_PAGE_CACHE_KEY, async () => {
    const { data } = await AxiosInstance({
      method: "get",
      url:
        config.BASE_API_URL +
        "housing_loan/home_loans/home_loans.json" +
        config.CMS_PARAMETERS,
    });
    return data;
  });
}
