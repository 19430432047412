import React from 'react';
import {Helmet} from 'react-helmet';
import {strings} from '../../constants/strings';

function MetaTag({description, title, keywords}) {
  return (
    <Helmet>
      <meta
        name="description"
        content={description || strings.META_TAG_DESCRIPTION}
      />
      <meta name="title" content={title || ''} />
      <meta name="keywords" content={keywords || ''} />
      <title>{title}</title>
      {/* <link rel="canonical" href="http://mysite.com/example" />  */}
    </Helmet>
  );
}

export default MetaTag;
