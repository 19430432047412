import {async} from '@firebase/util';
import {initializeApp} from 'firebase/app';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCXk-mdeGOVpLj0QgFDAXI7sxiZPRhETko',
  authDomain: 'lic-dialogflow-staging.firebaseapp.com',
  databaseURL: 'https://lic-dialogflow-staging.firebaseio.com',
  projectId: 'lic-dialogflow-staging',
  storageBucket: 'lic-dialogflow-staging.appspot.com',
  messagingSenderId: '696674333727',
  appId: '1:696674333727:web:4fd5eea2abace724fc912a',
  measurementId: 'G-TQHEJBG8GF',
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export const handleFirebaseFileUpload = async (file, uploadPath) => {
  if (file) {
    const storageRef = ref(getStorage(app), uploadPath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    return new Promise((res, rej) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            res(url);
          });
        },
      );
    });
  }
};

export const handleRemoveFirebaseFileUpload = (file) => {
  const fileRef = ref(storage, file);
  return new Promise((res, rej) => {
    deleteObject(fileRef)
      .then(() => res(true))
      .catch((error) => rej(error));
  });
};
